<template>
  <div class="mall-expand">
    <div class="content1">
      <h2>Expand into<br />own EC website</h2>
      <p class="sub-title">自社ECサイトへの展開による事業拡大</p>

      <p class="wrap">
        ショッピングモールから立ち上げを行った場合、モールへのアクセスデータや販売データを解析し、活用して、自社ECサイトの展開を効果的・効率的に行います。これによって販売の拡大を実現します。
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "mall-expand",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 20px;
  color: var(--main);
  margin: 50px 0 10px 0;
}
.note {
  text-align: right;
}
.mall-expand {
  .content1 {
    padding: 150px 0;
    // background-size: contain;
    background-size: cover;
    background-image: url(~@/assets/mall/syukyaku-EC-mo-004.jpg);
    @media screen and (max-width: 767px) {
      background-image: url(~@/assets/mall/syukyaku-EC-mo-003.jpg);
      padding: 80px 0;
      background-position: center bottom;
      background-color: rgba(255, 255, 255, 0.4);
      background-blend-mode: lighten;
    }
  }
}
</style>
