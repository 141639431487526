<template>
  <div class="marketing-develop">
    <h2>How to enhance staff<br />recruitment</h2>
    <p class="sub-title">
      求人専用サイト・求人ページで<br class="tb-sp-only" />求人を強化する方法
    </p>
    <div class="wrap">
      <p class="min">
        ここでお伝えする方法は、「年間を通して、ある程度の人数の採用が必要」「なんとしてでも人を採用する必要がある」「求人媒体にお金をかけているが人が来ない」という事業主が対象であり、「年間で1名採用したい」「採用にお金をかけられない」という事業主がとるべき方法ではありません。本当に求人に困っていて、「なんとかしたい」「解決策がほしい」という人だけ引き続きお読みください。
      </p>
      <p class="min">
        求人を強化するためには求人専用サイト、または、ホームページ内に求人詳細ページを作ることです。弊社には多数のクライアントがいますが、求人専用ページや求人詳細ページを持っているクライアントと、持っていないクライアントで、応募者の入り方が全く違います。
      </p>
      <p class="min">
        なぜ違うのかその理由は、求人専用ページや求人詳細ページを持つことに、以下のメリットがあるからです。
      </p>
    </div>

    <div class="back-main">
      <div class="wrap center">
        <p>求人専用ページや<br class="tb-sp-only" />求人詳細ページのメリット</p>
        <img
          alt="求人専用ページや求人詳細ページのメリット"
          src="@/assets/common/syukyaku-kyoutu-ira001.png"
          class="man"
        />
      </div>
    </div>
    <div class="wrap">
      <ul>
        <li>記載できる内容に制限がない。もっとも濃い情報を発信できる</li>
        <li>デザイン/写真/動画などで差別化やブランディングできる</li>
        <li>競合企業との情報比較で優位になる。候補に残りやすい</li>
        <li>自社サイトなので、他社の求人情報が近くにない状態で見てもらえる</li>
        <li>採用・求人に力を入れている企業だと印象付けることができる</li>
        <li>職者は最終的に、企業のホームページ・求人サイトを見て企業を選ぶ</li>
      </ul>

      <p class="mass">
        ここで挙げたのは対求職者への印象についてですが、求職者を集客するという点においても、求人専用サイトや求人ページは有利になります。これらについては、実際にお仕事の打ち合わせの際に具体的にご説明させていただきます。
      </p>
      <p class="mass">
        誰もが求人を出しているハローワークや有料求人媒体は、この逆で、記載内容に制限があり、全企業が同じフォーマット・外観で情報を掲載し、競合企業としっかり比較されてしまいます。このような状態を抜け出すために、自社で運営する求人専用サイトや求人詳細ページが必要になります。
      </p>
    </div>

    <div class="back-main">
      <div class="wrap center">
        <p>求人専用サイト・<br class="tb-sp-only" />求人詳細ページの作り方</p>
        <img
          alt="求人専用サイト・求人詳細ページの作り方"
          src="@/assets/common/syukyaku-kyoutu-ira002.png"
          class="man woman"
        />
      </div>
    </div>
    <div class="wrap">
      <p class="mass">
        では、どのように魅力的な求人専用サイト・求人詳細ページを作るのか、概要を説明していきます。具体的なノウハウはお仕事の打ち合わせ時にご説明させていただきます。
      </p>
      <p class="mass">
        まず、前提として、求人専用サイトや求人詳細ページは、「ただ求人条件を掲載しただけ」というものでは全く意味がありません。むしろ、中身がない、魅力がない会社に思われてしまい、マイナスになることがあります。ここで説明するように、求職者が見たときに、魅力を感じてもらうこと、印象に残ることが重要です。求人専用サイトや求人詳細ページは、簡素なものではなく、作りこまれたものである必要があります。
      </p>
      <p class="mass">
        そのために、弊社が膨大な調査・マーケティングによって導き出した内容について、ヒアリングをさせていただき、貴社の魅力を求職者視点で洗い出して、引き立て、良い求人条件と職場の良い見せ方を提案します。
      </p>
      <p class="mass">
        さらに、求人サイトへの集客を考慮し、集客が有利になる求人の職種設計についてもお教えし、そのように職種を設計していきます。ここで具体的な方法についてお伝えすると、模倣されてしまうため、お伝えはできませんが、これは多くの企業にとって盲点となっているノウハウです。
      </p>
      <p class="mass">
        そして、求人のターゲットを明確にし、そのターゲットに刺さる見せ方・言葉で求人サイトや求人ページのコンテンツ（内容）を設計し、構築していきます。
      </p>
      <p class="mass">
        このアプローチにそって求人サイト・求人ページを作ることで、求職者から魅力的な会社に思ってもらえるようになります。
      </p>
    </div>
    <div class="back-main">
      <div class="wrap center">
        <p>求人専用サイト・<br class="tb-sp-only" />求人詳細ページへの集客</p>
        <img
          alt="求人専用ページや求人詳細ページのメリット"
          src="@/assets/common/syukyaku-kyoutu-ira003.png"
          class="man"
        />
      </div>
    </div>
    <div class="wrap">
      <p class="mass">
        次は、求人サイト・求人ページへの集客です。これには、お金がかからない方法と、お金がかかるが応募が早く集まる方法があります。
      </p>
      <p class="mass">
        まずは、お金がかからない方法から実行していきます。この方法は、一度構築すれば、その仕組みが残って働き続けるため、永続的に求職者からの応募を見込むことができます。
      </p>
      <p class="mass">
        ここまで実行しても足りない、さらに人がほしい、早くほしい、という場合は、求人サイト・求人ページを有料広告に出稿して、ターゲットに合う求職者へアプローチしていきます。広告の運用を得意とする弊社だからこそ実現できる方法です。
      </p>
      <p class="mass">
        これが弊社の求人強化の方法です。ここでは具体的な説明はできませんが、お問い合わせいただいたお客様には、打ち合わせの際に、すべて説明させていただきます。
      </p>
      <p class="mass">
        「有料の求人媒体に求人を出す」というのが一般的なアプローチですが、こんな昔ながらの方法は、お金さえ出せば誰でもできるとてもカンタンな方法です。やっている事業主が多すぎて、求人を出しても人が来ないし、求人を出しているすべての会社と同じフォーマットの求人情報の見せ方となってしまい、差別化が難しいです。また、お金を払うのを止めると掲載がストップになってしまい、施策としての資産性が0です。これでは、ずっとお金がかかるばかりです。
      </p>
      <p class="mass">
        最新かつ効果的な方法は、自社の求人サイト・求人ページを持ち、そこで自社のブランディングと求職者にとって魅力的に見える情報の掲載を自由に行い、それをより多く露出させることです。何より、自社の求人サイト・求人ページは、一度作ればずっと残って、働いてくれます。資産性があるのです。
      </p>
      <p>
        求人に困っているが、「この方法はやっていない」「知らなかった」「やってみたい」「話を聞きたい」という事業主様はぜひお問い合わせください。
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiFreeConsulting from "@/components/Ui/FreeConsulting.vue";
export default {
  name: "marketing-develop",
  components: {
    // UiFreeConsulting,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.min {
  margin-bottom: 30px;
}

.coment {
  margin-bottom: 100px;
}

ul {
  list-style: none;
        @media screen and (max-width: 767px){
      padding-left: 0;
      }
  li {
    background: url(~@/assets/common/syukyaku-kyoutu-pa001.png) no-repeat 0 0;
    background-size: auto 42px;
    color: var(--main);
    font-size: 26px;
    padding: 0px 0 40px 80px;
    @media screen and (max-width: 767px) {
      font-size: 21px;
      background-size: auto 31px;
      padding: 0 0 27px 44px;
    }
  }
}
</style>
