<template>
  <div class="home-advantages">
    <h2>
      Advantages of<br />our website<br class="sp-only" />
      development
    </h2>
    <p class="sub-title">
      グレイトヘルプの<br class="sp-only" />ホームページ制作のメリット
    </p>
    <img
      alt="弊社側での業務対応の効率化によるコストカット"
      src="@/assets/ezgif.com-apng-maker-7.png"
      class="deco"
    />
    <div
      class="wrap-right"
      :class="{ 'over-baseline': displayData.advantage01 === true }"
    >
      <p
        class="blue-title transparent"
        :class="{ fadeIn: displayData.advantage01 === true }"
      >
        ホームページ制作による目的達成を一番に考え、<br
          class="pc-only"
        />実現できるスキルがある
      </p>
      <p
        class="text transparent"
        :class="{ fadeIn: displayData.advantage01 === true }"
      >
        上記のとおり、弊社は、ホームページ制作業者の中でも、有数の実績を保有しています。ホームページをただ形として作るだけでなく、売上アップや認知拡大、アクセス数増、求人強化など、希望・目的を実現できるホームページを作るノウハウとスキルを会社として有しています。
      </p>
    </div>

    <div
      class="wrap-left"
      :class="{ 'over-baseline': displayData.advantage02 === true }"
    >
      <p
        class="blue-title transparent"
        :class="{ fadeIn: displayData.advantage02 === true }"
      >
        マーケティングを駆使し、<br
          class="pc-only"
        />データに基づいたホームページの設計
      </p>
      <p
        class="text transparent"
        :class="{ fadeIn: displayData.advantage02 === true }"
      >
        弊社のホームページ制作は、インターネット上のデータを独自のノウハウで収集し、そのデータに基づいて設計していきます。無策・感覚でホームページを作ることをしていません。実データに基づいた設計だからこそ、刺さるホームページのコンテンツを制作することができます。
      </p>
    </div>

    <div class="wrap-right">
      <p class="blue-title">
        検索エンジン(SEO対策)を意識した<br
          class="pc-only"
        />ホームページの作り方
      </p>
      <p class="text">
        専門学校や大学で習うホームページの作り方や、書籍に書かれているホームページの作り方でも、ホームページ自体は制作することは可能です。しかし、ホームページを通して効果を得るには、それだけでは不十分で、検索エンジン(Google)を意識したホームページの作り方が重要になります。弊社は、常時500サイト以上・10,000以上の検索ワードの検索順位の取得と検証を行っており、Googleのアルゴリズムの普遍的な部分と、日々進化する部分の両方を押さえたホームページの構築をしています。
      </p>
    </div>

    <div class="wrap-left">
      <p class="blue-title">結果が出ているホームページの構成</p>
      <p class="text">
        オシャレさを追求して奇抜な構成のホームページを作ることも可能ですが、やはり、もっともホームページのパフォーマンスが上がる最適な構成があります。弊社は、これまで検証と改善を重ね、パフォーマンスが出やすいホームページの構成をノウハウとして保有しています。弊社にて、安価な内容でホームページを作る場合も、結果が出ている構成でホームページをお作りします。
      </p>
    </div>

    <div class="wrap-right">
      <p class="blue-title">クライアント目線の提案と制作料金</p>
      <p class="text">
        弊社はクライアントのご希望とご予算に合わせて、クライアントごとに制作するホームページの内容を提案しています。
      </p>
      <ul>
        <li>目的達成するための強力なホームページを制作することも可能</li>
        <li>予算内で実現できるベストなホームページを提案することも可能</li>
        <li>
          コストをなるべく落とすことを優先した安価なホームページを提案することも可能
        </li>
      </ul>
      <p class="text">
        クライアントによって、事業内容・強み・目的などは千差万別であり、一律の制作内容では、クライアントにとってベストなホームページはできません。そのため弊社は、一律の制作内容・一律制の料金体系を取っていません。ヒアリングの上、クライアントのニーズにマッチしたホームページをご提案しています。
      </p>
    </div>

    <div class="wrap-left">
      <p class="blue-title">まるまる仕事をお願いできる</p>
      <p class="text">
        ホームページ制作業者の中には、「ホームページに掲載する文章を書く技術がないから、文章はクライアントから提供してください」「撮影はできないから、写真は提供してください」というように、全業務を請け負えない業者もあります。弊社は、文章作成・コーディング・デザイン・写真撮影・イラスト作成など、ホームページ制作において必要なことはすべて対応できるので、貴社にて手間をかけることなく、また、依頼する業者を分割することなく、すべてお任せすることができます。
      </p>
    </div>

    <div class="wrap-right">
      <p class="blue-title">柔軟なコスト調整</p>
      <p class="text">
        ホームページ制作において必要なことを全て弊社に依頼することもできますが、逆に、依頼内容を調整することで、コストを削減することもできます。たとえば、「文章は貴社にてご用意をいただければこれだけコストが下がります」「写真はあるものを使用することでこれだけコストが下がります」というように、貴社が保有する資源をお聞きし、弊社へ依頼する業務範囲を調整することで、コスト調整ができる仕組みになっています。
      </p>
    </div>

    <div class="wrap-left">
      <p class="blue-title">
        システムを搭載した高機能なホームページを作る技術がある
      </p>
      <p class="text">
        ホームページの制作は、HTML・CSSという技術を学べばできるようになりますが、ネットショッピングの機能を付けたり、値を入力して数値シミュレーションする機能を加えたり、該当する条件の情報を表示する検索機能を加えたり、ということまでするとなれば、高度なプログラミング技術が必要になります。弊社は、システム開発も事業として強みを有しており、プログラミングの技術力があるため、ホームページに上述のようなシステムを搭載することも可能です。この技術があるからこそ、他社との差別化を実現する、高機能なホームページを制作することができます。
      </p>
    </div>

    <div class="wrap-right">
      <p class="blue-title">
        自分たちで記事を投稿したり、お知らせを投稿したりできる
      </p>
      <p class="text">
        お知らせを更新したり、自社に関する情報を発信したり、ということができる機能をホームページに搭載することも当然可能です。ホームページを更新したいときに、自分たちで更新していただくことができます。情報発信については、集客につながる記事の作成方法をノウハウとしてお教えすることもしています。
      </p>
    </div>

    <div class="wrap-left">
      <p class="blue-title">現在の業者からの切り替えもスムーズに対応</p>
      <p class="text">
        現在、契約している業者から弊社へ切り替えたい場合の対応もスムーズに行います。現在の業者へお伝えする内容などを弊社がすべて指示します。現在の業者との契約の確認もします。
      </p>
    </div>

    <div class="wrap-right">
      <p class="blue-title">制作後の集客業務まで請け負うことができる</p>
      <p class="text">
        弊社は、ホームページを制作するだけで、制作後は何もできない、してくれない、というホームページ制作会社ではありません。ホームページを通して売上増や認知拡大などのために、ホームページの改善、SEO対策、ネット広告の運用、SNSの運用など、制作後の集客業務まで請け負うことができます（別途料金にて）。制作後の集客や運用についてもご相談・ご依頼していただけます。
      </p>
    </div>

    <div class="wrap-left">
      <p class="blue-title">事業内容の価値を高める提案も可能</p>
      <p class="text">
        弊社の強みはマーケティング力です。ホームページを制作するにあたってのマーケティングに基づいて、「貴社のサービスはこうしたら、より販売力が高くなる」「このポジションが有利だ」「この集客方法が効果的だ」ということまで導くことができます。現在の事業のパワーが格段に上がった事例が多数あります。<span class="kome">（※マーケティング強化の依頼案件の場合）</span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiBox4 from "@/components/Ui/Box4.vue";

export default {
  name: "home-advantages",
  components: {
    // UiBox4
  },
  data() {
    return {
      documentheight: 0,

      //
      displayData: {
        advantage01: false,
        advantage02: false,
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl", "scrollY"]),
  },
  watch: {
    scrollY(newValue) {
      // advantage01
      if (newValue >= 14100) {
        this.displayData.advantage01 = true;
      }

      // advantage02
      if (newValue >= 14600) {
        this.displayData.advantage02 = true;
      }

      // advantage03
      //
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.home-advantages {
  position: relative;

  .deco {
    position: absolute;
    width: 150px;
    top: 0;
    right: 20%;
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      display: none;
    }
    @media screen and (max-width: 767px) {
      width: 60px;
      top: 100px;
      right: 7%;
    }
  }
}
.wrap-left,
.wrap-right {
  background-color: var(--sub3);
  margin-bottom: 50px;
  transition: all 0.8s ease;
  // clip-path: polygon(0 0, 0 0, 0 0, 0 0);

  @media screen and (max-width: 767px) {
    margin-bottom: 5px;
  }

  .blue-title {
    color: var(--main);
    font-size: 25px;
    margin-bottom: 20px;
    font-weight: 500;
    @media screen and (max-width: 767px) {
      font-size: 23px;
      line-height: 1.5em;
    }
  }
  .text {
    color: var(--black);
    line-height: 2;
  }
  // .transparent {
  //   opacity: 0;
  // }
  //アニメーション復活用
  .fadeIn {
    transition: opacity 2s 0.8s;
    opacity: 1;
  }
  :deep(ul) {
    margin: 30px 0;
  }
  @media screen and (max-width: 767px) {
    margin-right: 0;
  }
}
.over-baseline {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%) !important;
}

.wrap-left {
  margin-left: calc(calc(100vw - 1100px) * 0.5);
  padding: 50px calc(calc(calc(100vw - 1150px) * 0.5) + 105px) 50px 80px;
  @media screen and (max-width: 767px) {
    margin-left: 0;
    padding: 40px 20px;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-left: 0;
    padding: 40px 20px;
  }
}
.wrap-left:last-child {
  @media screen and (max-width: 1024px){
    margin: 0;
  }
}
.wrap-right {
  margin-right: calc(calc(100vw - 1100px) * 0.5);
  padding: 50px 100px 50px calc(calc(calc(100vw - 1150px) * 0.5) + 105px);
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-right: 0;
    padding: 40px 20px;
  }
  @media screen and (max-width: 767px) {
    margin-right: 0;
    padding: 40px 20px;
  }
}
:deep(h2),
.sub-title,
p {
  color: white;
}

:deep(.link-button) {
  a {
    color: white;
    border: white 1px solid;
    &:hover {
      background-color: white;
      color: var(--sub);
    }
  }
}

.over-baseline {
  clip-path: polygon(0 0, 200% 0, 0 200%);
}
</style>
