<template>
  <div class="home-our-achievements wrap">
    <h2>Our achievements</h2>
    <p class="sub-title">グレイトヘルプの実績</p>
    <img
      alt="キラキラ"
      src="@/assets/ezgif.com-apng-maker-4.png"
      class="deco3"
    />
    <div class="item">
      <div class="text">
        <h3>販売代行・アフィリエイトでの<br class="sp-only"/>実績多数</h3>
        <p>
          アフィリエイトをご存知でしょうか？提携する企業の商品やサービスを完全成果報酬で行う、インターネット上での販売代行のことです。弊社の創業のルーツはアフィリエイトにあり、これまで数々の商品・サービスの販売において「販売数日本一」を獲得してきました。販売できなければ利益を一切頂けないという成果報酬のマーケティングで長年実績を出している本物のマーケティングスキルが弊社にはあります。
        </p>
      </div>
      <img alt="理由イメージ１" src="@/assets/syukyaku-top-g002.png" />
    </div>

    <div class="item">
      <div class="text">
        <h3>日本全国の都市で<br />40回以上のセミナー登壇実績</h3>
        <p>
          ウェブマーケティング関連のセミナーに、これまで日本全国（東京・大阪・名古屋・福岡・札幌・仙台・神戸・広島・松山・鹿児島・沖縄）の都市で、合計40回以上、セミナーに登壇させていただきました。弊社のようなウェブ制作の事業者に向けて、インターネット集客、ホームページ制作などについてのセミナーを行っています。
        </p>
      </div>
      <img alt="理由イメージ１" src="@/assets/syukyaku-top-023.jpg" />
    </div>

    <div class="item">
      <div class="text">
        <h3>有名経営者・大手企業への<br />コンサルティング実績</h3>
        <p>
          連続起業家
          Dears北原孝彦氏へのアフィリエイトのコンサル、上場企業、上場企業子会社、西日本注目のベンチャー100に選出された企業、東海地区で100店舗超の美容院FCなど、大手・有名企業のホームページ制作や集客支援、コンサルティングの実績がいくつもあります。
        </p>
      </div>
      <img alt="理由イメージ１" src="@/assets/syukyaku-top-021.jpg" />
    </div>

    <div class="item">
      <div class="text">
        <h3>起業や新規事業の立ち上げから<br />事業の拡大の成功実績</h3>
        <p>
          起業や新規事業において、そのビジネススキームの構築、ホームページの制作、集客などを全面的に支援して、事業を拡大した事例も多数あります。創業から2年で5店舗展開した美容サロン、卸売による販売からネット通販へ参入して2年で年商が4倍になった食品工場、4年連続で過去最高売上を続ける歯科医院など、事業拡大の成功実績が多数あります。
        </p>
      </div>
      <img alt="理由イメージ１" src="@/assets/syukyaku-top-022.jpg" />
    </div>
    <img alt="先生" src="@/assets/ezgif.com-apng-maker-5.png" class="deco1" />
    <img alt="生徒" src="@/assets/ezgif.com-apng-maker-6.png" class="deco2" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiBox4 from "@/components/Ui/Box4.vue";
export default {
  name: "home-our-achievements",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.home-our-achievements {
  position: relative;
  .item {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 70px 0;
    border-bottom: 2px white solid;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    .text {
      width: 68%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      h3 {
        color: var(--main);
        text-align: left;
        font-size: 25px;
        margin-bottom: 20px;
        @media screen and (max-width: 767px){
          font-size: 23px;
        }
      }
    }
    img {
      width: 30%;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 20px;
      }
    }
    &:nth-of-type(1) {
      padding-top: 0;
    }
    &:nth-of-type(4) {
      border: none;
      padding-bottom: 20px;
    }
  }
  .deco1,
  .deco2,
  .deco3 {
    position: absolute;
  }
  .deco1 {
    width: 100px;
    bottom: 0;
    left: -15%;
    @media screen and (max-width: 767px) {
      width: 100px;
      left: 50px;
      display: none;
    }
  }
  .deco2 {
    width: 200px;
    bottom: -100px;
    right: -150px;
@media screen and (min-width:768px) and ( max-width:1024px){
  right: 3%;
}
    @media screen and (max-width: 767px) {
      width: 150px;
      right: 0;
      left: 20px;
      bottom: -55px;
      transform: scale(-1, 1);
    }
  }
  .deco3 {
    width: 100px;
    top: -20px;
    right: 170px;
    @media screen and (max-width: 767px) {
      width: 70px;
      right: 20px;
      top: -100px;
      transform: scale(1, -1);
    }
  }
}
</style>
