<template>
  <div class="mall-advantages">
    <h2>Advantages of<br />systemization</h2>
    <p class="sub-title">システム化によるメリット</p>
    <div class="wrap">
      <p class="mass">
        システム化をカンタンに定義すれば、「人の手で行っているルーティン的な業務を、コンピューターにやらせることで業務を自動化・効率化する」ということです。たとえばですが、美容室を想像してみてください。
      </p>
      <p class="mass">
        受付にセレプションのスタッフを1名配置して、来店があったお客様の対応をします。もしくは、髪を切るスタイリストがレセプション役を兼任して、お客様の対応をします。お名前をお聞きし、ご希望のメニューを確認し、紙のヒアリングシートをお渡しし、お客様に記入をしてもらいます。そして、お客様が退店されたら、お客様の名前や電話番号などのお客様情報をパソコンに手入力します。次回、お客様からご予約があると、書いてもらったヒアリングシートを探し、来店時まで用意し、お客様を迎えます。
      </p>
      <div class="flow">
        <div class="balloon2">
          <p>
            美容室でよく行われている業務ですが、<br
              class="pc-only"
            />この業務には以下の問題点があります。
          </p>
        </div>
        <div class="white">
          <ul>
            <li>スタッフがお客様対応をする工数が発生する</li>
            <li>お客様情報をパソコンへ入力し忘れることがある</li>
            <li>お客様情報をパソコンへ入力する際に間違えることがある</li>
            <li>紙面のヒアリングシートを探す手間が発生する</li>
            <li>紙面のヒアリングシートを紛失してしまうときがある</li>
            <li>紙面のヒアリングシートを保管する場所が必要になる</li>
          </ul>
          <img
            alt="問題点の説明"
            src="@/assets/system/syukyaku-sytem-ira001.png"
            class="woman"
          />
        </div>
        <div class="yazirushi-cover">
          <img
            alt="矢印"
            src="@/assets/system/syukyaku-sytem-pa003.png"
            class="yazirushi"
          />
          <p>この業務をシステム化によって、<br />以下のように改善しました。</p>
        </div>
        <div class="white noimg">
          <ul>
            <li>
              お客様情報は、タブレットでお客様自身でご入力をしていただく仕組みへ
            </li>
            <li>
              ヒアリング内容は、タブレットでお客様自身でご入力をしていただく仕組みへ
            </li>
            <li>
              お客様の来店時は、お客様の名前をシステム上で検索すると、お客様情報とヒアリング内容が表示され、確認できる仕組みへ
            </li>
            <li>
              登録した情報は、自動バックアップ機能によって保存される仕組みへ
            </li>
          </ul>
        </div>
        <div class="yazirushi-cover">
          <img
            alt="矢印"
            src="@/assets/system/syukyaku-sytem-pa003.png"
            class="yazirushi"
          />
          <p>その結果、<br />以下のようになりました。</p>
        </div>
        <div class="white">
          <ul>
            <li>スタッフのお客様対応の工数がなくなった</li>
            <li>お客様情報の入力忘れ、入力間違いがなくなった</li>
            <li>ヒアリングシートを探す手間も、保管するスペースもなくなった</li>
            <li>ヒアリングシートを紛失することがなくなった</li>
            <li>
              店舗にいなくても、パソコンからお客様情報やヒアリングシートを確認できるようになった
            </li>
            <li>紙面のヒアリングシートを保管する場所が不要になった</li>
          </ul>
          <img
            alt="結果の説明"
            src="@/assets/system/syukyaku-sytem-ira002.png"
            class="woman"
          />
          <img
            alt="はなまる"
            src="@/assets/system/syukyaku-sytem-ira003.png"
            class="hanamaru"
          />
        </div>
        <div class="balloon3">
          <p>
            この事例を抽象的にまとめると、<br
              class="pc-only"
            />システム化には以下のメリットがあります。
          </p>
        </div>
      </div>
    </div>
    <div
      class="wrap-right"
      :class="{ 'over-baseline': displayData.advantage01 === true }"
    >
      <p
        class="blue-title transparent"
        :class="{ fadeIn: displayData.advantage01 === true }"
      >
        従業員のコスト削減
      </p>
      <p
        class="text transparent"
        :class="{ fadeIn: displayData.advantage01 === true }"
      >
        従業員で人的な対応をしている限り、その従業員のコストは永遠に発生します。当然ですが、従業員の採用も必要となり、かつ、雇用のリスクも伴います。しかし、システム化をしてしまえば、従業員へのコストは発生しなくなります。また、従業員を採用する必要もなければ、管理する必要もなければ、様々な雇用上のリスクもなくなります。まさに、人手不足のこれからの時代に必要なアプローチです。
      </p>
    </div>

    <div
      class="wrap-left"
      :class="{ 'over-baseline': displayData.advantage02 === true }"
    >
      <p
        class="blue-title transparent"
        :class="{ fadeIn: displayData.advantage02 === true }"
      >
        ミスの撲滅
      </p>
      <p
        class="text transparent"
        :class="{ fadeIn: displayData.advantage02 === true }"
      >
        人が作業をすると人為的なミスやモレが発生するものです。しかし、コンピュータが作業をするとなると、常に一定の正確な処理をしてくれます。そのため、業務のミスや対応モレが発生しなくなります。つまりは、業務の進行がスムーズになり、業務の品質という観点でも改善されます。
      </p>
    </div>

    <div class="wrap-right">
      <p class="blue-title">保管スペースの削減</p>
      <p class="text">
        お客様情報やヒアリングシートを紙面で管理していると、その保管スペースが必要になります。この保管スペース＝賃料に相当するため、積み重なるとバカにはなりません。紙面での管理から、システム上での管理に変えることで、紙面の保管スペースを削減できます。
      </p>
    </div>

    <div class="wrap-left">
      <p class="blue-title">紛失リスクの撲滅</p>
      <p class="text">
        紙面という物理を伴う保存方法だからこそ、紛失や破損が起こり得ます。半面、電子データでの管理にし、データのバックアップを行えば、紛失や破損は起こりません。このように、データの保存という観点からも信頼度が上がります。
      </p>
    </div>

    <div class="wrap-right">
      <p class="blue-title">移動の削減</p>
      <p class="text">
        紙面という物理を伴うからこそ、その現物を見るために、現地に行く必要があります。半面、電子データでの管理にすれば、そのデータはクラウドを介して、自宅でもスマホでも、どこにいても確認することができます。このように、データの確認に際して移動が発生せず、業務の利便性が高くなります。
      </p>
    </div>

    <p class="wrap last-block">
      ここまで説明したとおり、システム化は、事業において大きなメリットがあります。特に、従業員のコストと採用という点でのメリットが大きく、これからの時代に欠かせない業務改善のアプローチとなります。
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiBox4 from "@/components/Ui/Box4.vue";

export default {
  name: "mall-advantages",
  components: {
    // UiBox4
  },
  data() {
    return {
      documentheight: 0,

      //
      displayData: {
        advantage01: false,
        advantage02: false,
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl", "scrollY"]),
  },
  watch: {
    scrollY(newValue) {
      // advantage01
      if (newValue >= 15560) {
        this.displayData.advantage01 = true;
      }

      // advantage02
      if (newValue >= 16200) {
        this.displayData.advantage02 = true;
      }

      // advantage03
      //
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.wrap-left,
.wrap-right {
  background-color: var(--sub3);
  margin-bottom: 50px;
  transition: all 0.8s ease;
  // clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  @media screen and (max-width: 767px) {
    margin-bottom: 5px;
  }

  .blue-title {
    color: var(--main);
    font-size: 25px;
    margin-bottom: 20px;
    font-weight: 500;
    @media screen and (max-width: 767px) {
      font-size: 23px;
      line-height: 1.5em;
    }
  }
  .text {
    color: var(--black);
    line-height: 2;
  }
  // .transparent {
  //   opacity: 0;
  // }
  .fadeIn {
    transition: opacity 2s 0.8s;
    opacity: 1;
  }
  :deep(ul) {
    margin: 30px 0;
  }
  @media screen and (max-width: 767px) {
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.over-baseline {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%) !important;
}

.wrap-left {
  margin-left: calc(calc(100vw - 1100px) * 0.5);
  padding: 50px calc(calc(calc(100vw - 1150px) * 0.5) + 105px) 50px 80px;
  @media screen and (max-width: 1024px) {
    margin-left: 0;
    padding: 40px 20px;
  }
}
.wrap-right {
  margin-right: calc(calc(100vw - 1100px) * 0.5);
  padding: 50px 100px 50px calc(calc(calc(100vw - 1150px) * 0.5) + 105px);
  @media screen and (max-width: 1024px) {
    margin-right: 0;
    padding: 40px 20px;
  }
}
:deep(h2),
.sub-title,
p {
  color: white;
}

:deep(.link-button) {
  a {
    color: white;
    border: white 1px solid;
    &:hover {
      background-color: white;
      color: var(--sub);
    }
  }
}

.over-baseline {
  clip-path: polygon(0 0, 200% 0, 0 200%);
}

.flow {
  .balloon2,
  .balloon3 {
    position: relative;
    display: inline-block;
    margin: 50px 0 20px 0;
    width: 100%;
    padding: 20px 0;
    color: white;
    background: var(--main);
    box-sizing: border-box;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 767px) {
      padding: 20px 30px;
    }
    p {
      margin: 0;
      padding: 0;
      font-size: 26px;
      @media screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 1.5;
      }
    }
  }
  .balloon2 {
    margin: 50px 0 20px 0;
  }
  .balloon3 {
    margin: 20px 0 50px 0;
  }

  .balloon2:before {
    content: "";
    position: absolute;
    bottom: -71px;
    right: 10%;
    margin-left: -15px;
    border: 26px solid transparent;
    border-top: 47px solid var(--main);
    z-index: 2;
    @media screen and (max-width: 767px) {
      bottom: -68px;
      border-top: 60px solid var(--main);
    }
  }
  .balloon3:before {
    content: "";
    position: absolute;
    top: -70px;
    left: 10%;
    margin-left: -15px;
    border: 26px solid transparent;
    border-bottom: 47px solid var(--main);
    z-index: 2;
    @media screen and (max-width: 767px) {
      margin-left: 0px;
      border-bottom: 71px solid var(--main);
    }
  }

  .white {
    background-color: white;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    position: relative;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    ul {
      width: 70%;
      margin-left: 100px;
      color: var(--main);
      font-size: 22px;
      @media screen and (max-width: 767px) {
        width: 85%;
        margin-left: 0;
        font-size: 20px;
      }
      li {
        padding-bottom: 10px;
      }
    }
    .woman {
      width: 23%;
      margin-right: 30px;
      @media screen and (max-width: 767px) {
        width: 50%;
        margin-right: 0;
      }
    }
    .hanamaru {
      width: 180px;
      position: absolute;
      top: 8%;
      right: -100px;
      animation: anime1 0.5s ease 0s infinite alternate;
                @media screen and (min-width:768px) and ( max-width:1024px){
      right: 0;
      width: 130px;
      }
      @media screen and (max-width: 767px) {
        top: 75%;
        width: 100px;
        right: 0;
      }
    }
    @keyframes anime1 {
      0% {
         transform: scale(0.9,0.9);}
      100% {
        transform: scale(1,1);
      }
    }
  }
  .noimg {
    justify-content: flex-start;
    align-items: flex;
    ul {
      width: 80%;
                @media screen and (min-width:768px) and ( max-width:1024px){
            width: 70%;
      }
      @media screen and (max-width: 767px) {
        width: 85%;
        margin-left: 0;
        font-size: 20px;
      }
    }
  }
  .yazirushi-cover {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 20px 0;
    .yazirushi {
      width: 150px;
    }
    p {
      position: absolute;
      text-align: center;
      line-height: 1.5;
      top: 30%;
    }
  }
}
.last-block {
  padding-top: 50px;
}
</style>
