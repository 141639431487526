<template>
  <div class="marketing-develop">
    <h2>How to improve your impression<br />with the design of your website</h2>
    <p class="sub-title">デザインでイメージアップする方法</p>

    <div class="back-main">
      <div class="wrap center">
        <p>デザインに対する<br class="sp-only" />弊社の方針</p>
        <img
          alt="求人専用ページや求人詳細ページのメリット"
          src="@/assets/common/syukyaku-kyoutu-ira001.png"
          class="man"
        />
      </div>
    </div>

    <div class="wrap min">
      <p class="mass">
        まずはデザインに対する弊社の方針についてです。弊社は、デザインを専門とした会社、つまり、デザイン会社ではありません。ウェブマーケティングに強みがある会社です。そのため、社内には限られた数のデザイナーしか在籍していません。
      </p>
      <p class="mass">
        お客様から依頼の中には、「希望のデザインを追求するべく、何案も作ってほしい」というものもありますが、社内に限られた数のデザイナーしかいないこと、また、初案からベストな提案をすることをスタンスとしているため、何案もやり直しをしてデザインを提案する、という対応はしていません。
      </p>
      <p class="mass">
        デザイン会社に仕事を依頼すれば、何案も提案をしてもらえると思いますが、その場合、コストは高くなってしまいます。最終的にホームページのデザインとして採用されるのは1案です。その1案のために、ボツになる案がたくさんあり、それらにも当然コストがかかる、という方法が正しい方法だと考えていません。依頼者の自己満足のみになってしまうのがオチです。
      </p>

      <p class="mass">
        弊社は、「デザイン」よりも「ホームページのパフォーマンス」を重視したホームページの制作をスタンスとしています。デザインを追求することで、奇抜な構成のホームページを提案するデザイナーもいますが、奇抜な構成のホームページは閲覧者にとってわかりづらく、ホームページのパフォーマンスは必ず低下します。お客様からが奇抜な構成のデザインを希望される場合は、その対応もできますが、ホームページへの投資・パフォーマンスという観点から、弊社は全くおすすめしていません。
      </p>
      <p class="mass">
        弊社は、デザインによるイメージアップをホームページで目指す場合も、無駄な投資をお客様にさせないこと、ホームページのパフォーマンスを下げないことを前提に行います。
      </p>
      <p class="mass">
        ホームページのデザインで重要なことは、以下の3点だと弊社は考えています。
      </p>
    </div>

    <div class="cover wrap">
      <div class="min">
        <div class="title-cover">
          <img
            alt="魅力的な写真を用いてデザインする"
            src="@/assets/design/syukyaku-de-pa004.png"
          />
          <h4>魅力的な写真を用いてデザインする</h4>
        </div>
        <p>
          弊社では、お客様の事業内容・ニーズ・ご予算に合わせて、提携のカメラマンを手配し、撮影を行います。
        </p>
        <ul>
          <li>物の撮影が得意なカメラマン</li>
          <li>人物写真の撮影を得意とするカメラマン</li>
          <li>動画撮影が得意なカメラマン</li>
          <li>コスト重視のカメラマン</li>
        </ul>
        <p class="last">
          カメラマンといっても、得手不得手も、価格帯も違います。案件とご予算に合わせた最適なカメラマンで撮影を行い、ホームページで使用する写真を用意します。
        </p>

        <div class="title-cover">
          <img
            alt="平面レベルでの見た目の印象を追求する"
            src="@/assets/design/syukyaku-de-pa005.png"
          />
          <h4>平面レベルでの見た目の印象を追求する</h4>
        </div>
        <p class="last">
          続いて、平面レベルでの見た目の印象についてです。従来のデザインと言えば、平面レベルでの見た目の印象の追求です。ご希望のイメージに合うデザインであること、商材が魅力的に見えるデザインであること、ターゲットを意識したデザインであることなど、デザインを創作して、作り込みます。お客様にヒアリングの上、弊社にあるデザインの事例をお見せしながら、イメージのすり合わせを行い、デザインを作っていきます。
        </p>
        <div class="title-cover">
          <img
            alt="ウェブ特有の動きによる見た目の印象を付ける"
            src="@/assets/design/syukyaku-de-pa006.png"
          />
          <h4>ウェブ特有の動きによる見た目の印象を付ける</h4>
        </div>
        <p>
          ウェブの場合は、ウェブ特有の動きによる見た目の印象を追求することができます。たとえば、以下の要素です。
        </p>
        <ul>
          <li>ヘッダー部分を動画にする</li>
          <li>ホームページ上の要素が動くようにする</li>
          <li>動き方に特徴をつける</li>
        </ul>
        <p class="mass">
          ここまで説明したアプローチで、ホームページのデザインによるイメージアップを目指します。
        </p>
        <p>
          このアプローチによって、実際に制作したホームページの事例をお見せします。他の仕様のホームページの制作事例は、弊社のノウハウを保護する目的から、ホームページ上で公開しないスタンスです。ここで公開するホームページは、クライアントの希望で「掲載する情報を最小限にしたい」とのことで、弊社にて特にマーケティングなどは行っていません。デザインのみを追求しました。そのため、公開することに、弊社・クライアントとも、不利益はないと判断したため、公開することにしました。
        </p>

        <div class="blue-bar">
          <p>制作事例：匠味 平野さま</p>
        </div>
        <div class="ex">
          <p class="des">
            ミシュラン一つ星を獲得した岐阜の日本料理店「匠味
            平野」様のホームページです。
          </p>
          <img
            alt="制作事例"
            src="@/assets/design/syukyaku-de-002.jpg"
            class="hp"
          />

          <a
            href="https://takumi-hirano.com/"
            target="_blank"
            rel="noopener noreferrer"
            ><div class="button">ホームページを見てみる</div>
          </a>
        </div>
        <img
          alt="見ている人"
          src="@/assets/design/ezgif.com-gif-maker-C.png"
          class="eye"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiFreeConsulting from "@/components/Ui/FreeConsulting.vue";
export default {
  name: "marketing-develop",
  components: {
    // UiFreeConsulting,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.min {
  margin-bottom: 0;
}
.wrap {
  @media screen and (max-width: 767px) {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
.coment {
  margin-bottom: 100px;
}

.cover {
  border: var(--main) solid 1px;
  padding: 100px 0;
  position: relative;
  margin-top: 100px;
  @media screen and (max-width: 767px) {
    padding: 50px 20px;
    width: 100%;
    margin-top: 0;
    border: none;
  }
}
.eye {
  position: absolute;
  bottom: 0;
  width: 150px;
  right: 50px;
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.title-cover {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  img {
    width: 100px;
    @media screen and (max-width: 767px) {
      width: 70px;
    }
  }
  h4 {
    font-size: 30px;
    color: var(--main);
    width: calc(95% - 100px);
    @media screen and (max-width: 767px) {
      font-size: 23px;
      width: calc(95% - 70px);
    }
  }
}
ul {
  list-style: disc;
  @media screen and (max-width: 767px) {
    margin: 30px 0;
  }
  li {
    color: var(--main);
  }
}
.last {
  padding-bottom: 100px;
  @media screen and (max-width: 767px) {
    padding-bottom: 50px;
  }
}
.blue-bar {
  background-color: var(--main);
  color: white;
  text-align: center;
  padding: 10px 0;
  margin-top: 100px;
  margin-bottom: 30px;
  @media screen and (max-width: 767px) {
    margin-top: 50px;
  }
}
.ex {
  text-align: center;
  .des {
    margin-bottom: 50px;
  }
}

.hp {
  width: 80%;
  margin: 0 auto;
}
a {
  text-decoration: none;
  color: var(--main);
  display: inline-block;

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--main);
    padding: 10px 20px;
    width: 300px;
    margin: 20px auto 0;
    transition: 3s;
    &:hover {
      background-color: var(--main);
    }
  }
  &:hover {
    color: white;
  }
}
</style>
