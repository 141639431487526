<template>
  <div class="home-operation-services wrap">
    <h2>Operation services<br />we can offer</h2>
    <p class="sub-title">対応可能な運用系サービス</p>
    <div class="box-cover">
      <UiBox4
        v-for="box in boxes"
        :key="box.id"
        :imgPath="box.imgPath"
        :subTitle="box.subTitle"
        :text="box.text"
        :linkPath="box.linkPath"
        :button="false"
        class="box"
      />
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBox4 from "@/components/Ui/Box4.vue";
export default {
  name: "home-operation-services",
  components: { UiBox4 },
  data() {
    return {
      scrollY: 0,
      scrollTimer: null,
      documentheight: 0,
      boxes: [
        {
          id: 1,
          imgPath: "syukyaku-top-010.jpg",
          subTitle: "ホームページ解析＋SEO対策",
          text: "SEO対策の強化・改善として、ホームページの制作後、ホームページのアクセスや検索順位などのデータを取得し、そのデータに基づいてホームページの改善案を提案・実行します。",
          linkPath: "/",
        },
        {
          id: 2,
          imgPath: "syukyaku-top-011.jpg",
          subTitle: "インターネット広告運用",
          text: "Google広告、Yahoo!広告、Facebook広告の出稿によって、集客・販売を強化します。弊社のホームページ制作とセットでの広告運用で、パフォーマンスが最大化します。",
          linkPath: "/",
        },
        {
          id: 3,
          imgPath: "syukyaku-top-012.jpg",
          subTitle: "SNS運用",
          text: "<span>Instagram、Facebook、XなどのSNSの運用を代行、または、アドバイスします。フォロワー数を増やすインフルエンサーを目指す方法と、着実に見込み客を集めることを目指す方法があります。</span>",
          linkPath: "/",
        },
        {
          id: 4,
          imgPath: "syukyaku-top-013.jpg",
          subTitle: "Googleビジネスプロフィール",
          text: "GoogleMap、Google検索対策となるGoogleビジネスプロフィールのコンテンツ制作、運用、口コミ獲得など全面的にサポート可能です。",
          linkPath: "/",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
:deep(h2),
.sub-title,
p {
  color: white;
}
.box-cover {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  @media screen and (max-width: 767px){
  flex-direction: column;
  }
  .box {
    width: 45%;
        margin-bottom: 80px;
          @media screen and (max-width: 767px){
  width: 100%;
  }
    :deep(.sub-title) {
      color: white;
    }
    :deep(.text-area) {
      p {
        color: white !important;
        // span{
        //   letter-spacing: -0.05em;
        // }
      }
    }
  }
}
:deep(.link-button) {
a{
  color: white;
  border:white 1px solid;
  &:hover{
    background-color: white;
    color: var(--sub);
  }
}
}

.over-baseline {
  clip-path: polygon(0 0, 200% 0, 0 200%);
}
</style>
