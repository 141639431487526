<template>
  <div class="home">
    <div class="top mb-150">
      <EcTop />
    </div>

    <div class="pt-150 pb-150 background-color">
      <EcDevelop />
    </div>

    <div class="pt-150 pb-150 background-color2">
      <EcOffer />
    </div>

    <div class="pt-150 pb-150 background-color">
      <EcSell />
    </div>

    <div class="pt-150 pb-150 pb-non">
      <EcReasons />
    </div>
    <div class="background-color pt-150 pb-150">
      <HomeOurAchievements />
    </div>

    <div class="background-color2 pt-150 pb-150 pb-non">
      <EcAdvantages />
    </div>

    <div
      class="parallax_content1"
      v-prlx.mobile.background="{
        direction: 'y',
        fromBottom: true,
        speed: 0.1,
      }"
    ></div>

    <div class="pt-150">
      <EcMalls />
    </div>

    <div class="pt-150 pb-150">
      <EcPrice />
    </div>

    <div class="background-color pt-150 pb-150">
      <EcQa />
    </div>

    <div class="pt-150 pb-150 pb-non">
      <EcFlow />
    </div>

    <div class="pt-150 pb-150 pt-non">
      <HomeContact :type="`ec`" :consul="true" />
    </div>

    <div class="background-color pt-150 pb-150 pb-non">
      <HomeServices />
    </div>

    <!-- <div class="background-color2 pt-250 pb-250">
      <HomeUseflInfo />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EcTop from "@/components/Ec/Top.vue";
import EcDevelop from "@/components/Ec/Develop.vue";
import EcOffer from "@/components/Ec/Offer.vue";
import EcSell from "@/components/Ec/Sell.vue";
import EcReasons from "@/components/Ec/Reasons.vue";
import HomeOurAchievements from "@/components/Home/OurAchievements.vue";
import EcAdvantages from "@/components/Ec/Advantages.vue";
import EcMalls from "@/components/Ec/Malls.vue";
import EcPrice from "@/components/Ec/Price.vue";
import EcQa from "@/components/Ec/Qa.vue";
import EcFlow from "@/components/Ec/Flow.vue";
import HomeContact from "@/components/Home/Contact.vue";
import HomeServices from "@/components/Home/Services.vue";
// import HomeUseflInfo from "@/components/Home/UseflInfo.vue";

export default {
  name: "Home",
  components: {
    EcTop,
    EcDevelop,
    EcOffer,
    EcSell,
    EcReasons,
    HomeOurAchievements,
    EcAdvantages,
    EcMalls,
    EcPrice,
    EcQa,
    EcFlow,
    HomeContact,
    HomeServices,
    // HomeUseflInfo,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
.background-color {
  background-color: var(--sub3);
}
.background-color2 {
  background-color: var(--sub);
}
.parallax_content1 {
  padding: 250px 0;
  background-image: url(~@/assets/ec/syukyaku-EC-websit-back001.jpg);
  background-size: cover;
            @media screen and (min-width:768px) and ( max-width:1024px){
    padding: 150px;
    }
  @media screen and (max-width: 767px) {
    background-size: auto 100vw;
   padding: 120px 0;
  }
}
</style>
