<template>
  <div class="home-reasons wrap">
    <h2>Reasons to choose<br>our service</h2>
    <p class="sub-title">弊社のECサイト制作サービスが<br class="sp-only">選ばれる理由</p>
    <div class="item">
      <div class="text">
        <h3>
          ECサイト制作のプロとして、<br>安心してお仕事をご依頼いただけます
        </h3>
        <p>
          弊社は岐阜市で創業から13年、これまでホームページとECサイトを合わせて500サイト以上を制作してきました。そして、ただ形としてホームページやECサイトを作るのではなく、「結果」「目的達成」を提供しているからこそ、これまで解約や他社への切り替えは0件(※)です。技術の進化が早く、業者の廃業や、制作後の他社への切り替えが発生しやすいウェブのお仕事において、安心してお仕事をご依頼いただけるのが弊社だと自負しています。<span
            >(※)集客・販売強化・求人強化のホームページ制作、ECサイト制作における実績</span
          >
        </p>
      </div>
      <img alt="理由イメージ１" src="@/assets/syukyaku-top-ira002.png" />
    </div>

    <div class="item col">
      <img alt="理由イメージ２" src="@/assets/ezgif.com-apng-maker-3.png" />
      <div class="text">
        <h3>
          売上アップの実績多数！<br>売れるECサイトを制作します
        </h3>
        <p>
          新規でECサイトのお仕事をご依頼いただくことが多いですが、多くのケースでクライアントの目標を達成しています。食品のBtoB向けの卸売販売からBtoC向けの通販に参入して3年で年商が3倍弱になった事例、化粧品の通販でクライアントの目標売上の2倍を達成した事例など、結果を提供しています。完全成果報酬のアフィリエイトを長年している弊社だからこそ、通販での販売を得意としています。
        </p>
      </div>
    </div>

    <div class="item">
      <div class="text">
        <h3>
          ご予算と達成したい目的をお聞きし、<br class="pc-only">予算内で目的を達成するための提案をします
        </h3>
        <p>
          ECサイト制作の実務はわかりづらいため、小難しい話とともに、クライアントの予算を上げるスタンスの業者もいます。弊社は、そのような対応は「トラブルのもと」だと考えていて、クライアントから「ご予算と達成したい目的をお聞きし、予算内で目的を達成するための方法」を考えて、提案します。制作内容の調整などは生じますが、予算オーバーになることなく、安心してご依頼いただけます。また、非現実的な目標を掲げていたり、不要な機能の搭載を希望されたりする場合は、「自社の利益のために仕事を受ける」のではなく、「理由の説明とともに、現実的な実行内容を提案」する、誠実な対応をしています。
        </p>
      </div>
      <img alt="理由イメージ３" src="@/assets/syukyaku-top-ira003.png" class="sp-right"/>
    </div>

    <div class="item col">
      <img alt="理由イメージ４" src="@/assets/syukyaku-top-ira004.png" />
      <div class="text">
        <h3>
          各スキルのプロを抱える組織のため、<br class="pc-only">すべての業務を対応でき、業務が効率的です
        </h3>
        <p>
          個人事業主や小規模法人のウェブ制作業者は、一人や少人数で業務対応をしています。それに対して、弊社は、マーケティング、プログラム、デザイン、ライティングと、それぞれ専門のスキルを保有するスタッフにて業務を対応しています。そのため、高いスキルの提供が可能です。また、ECサイト以外の業務をご依頼する際も弊社にて対応可能なため、案件によって業者を探す・変える必要がなく、業者を1本化して効率的なご依頼ができます。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Home-any-concerns-or-problems",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 100px;
    @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  h3 {
    color: var(--main);
    text-align: left;
    font-size: 25px;
    margin-bottom: 18px;
        @media screen and (max-width: 767px){
      font-size: 23px;
    }
  }
  .text {
    width: 75%;
        @media screen and (max-width: 767px) {
      width: 100%;
    }
    span {
      font-size: 15px;
    }
  }
  img {
    width: 20%;
        @media screen and (max-width: 767px) {
      width: 50%;
      margin: 30px auto 0;
      display: block;
    }
  }
    .sp-right{
    @media screen and (max-width: 767px) {
margin-right: 0;
    }
  }
}
  .col {
  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
  }
}
</style>
