<template>
  <div class="home">
    <div class="top mb-150">
      <DtpTop />
    </div>

    <div class="pt-150 pb-150 background-color pb-30">
      <DtpCreate />
    </div>


    <div class="pt-150 pb-150">
      <DtpPrice />
    </div>

    <div class="background-color pt-150 pb-150">
      <DtpQa />
    </div>

    <div class="pt-150 pb-150">
      <DtpFlow />
    </div>

    <div class="pt-150 pb-150 pt-non">
      <HomeContact :consul="false"/>
    </div>

    <div class="background-color pt-150 pb-150 pb-non">
      <HomeServices />
    </div>

    <!-- <div class="background-color2 pt-250 pb-250">
      <HomeUseflInfo />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DtpTop from "@/components/Dtp/Top.vue";
import DtpCreate from "@/components/Dtp/Create.vue";
import DtpPrice from "@/components/Dtp/Price.vue";
import DtpQa from "@/components/Dtp/Qa.vue";
import DtpFlow from "@/components/Dtp/Flow.vue";
import HomeContact from "@/components/Home/Contact.vue";
import HomeServices from "@/components/Home/Services.vue";
// import HomeUseflInfo from "@/components/Home/UseflInfo.vue";

export default {
  name: "Home",
  components: {
    DtpTop,
    DtpCreate,
    DtpPrice,
    DtpQa,
    DtpFlow,
    HomeContact,
    HomeServices,
    // HomeUseflInfo,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
.background-color {
  background-color: var(--sub3);
}
.background-color2 {
  background-color: var(--sub);
}
.pb-30{
  @media screen and (max-width: 767px){
    padding-bottom: 30px;
  }
}
</style>
