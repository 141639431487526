<template>
  <div class="marketing-develop">
    <h2>Sales strategy<br />at shopping malls</h2>
    <p class="sub-title">ショッピングモールでの販売戦略</p>
    <div class="wrap">
      <p class="min">
        弊社の、日本の主要ショッピングモールでの販売の戦略は以下です。
      </p>

      <div class="box min">
        <img alt="01" src="@/assets/common/syukyaku-kyoutu-maru001.png" />
        <p>
          案件に合わせて、販売が有利で、販売が見込める、一発目に出店するモールを選定する
        </p>
      </div>
      <div class="box min">
        <img alt="02" src="@/assets/common/syukyaku-kyoutu-maru002.png" />
        <p>
          一発目に出店したモールで作成したコンテンツを流用することで、次のモールを効率的に立ち上げる
        </p>
      </div>
      <div class="box min">
        <img alt="03" src="@/assets/common/syukyaku-kyoutu-maru003.png" />
        <p>このアプローチで日本の主要モールへ展開し、制覇する</p>
      </div>
      <div class="box min sp-bt">
        <img alt="04" src="@/assets/common/syukyaku-kyoutu-maru004.png" />
        <p>販売に影響が大きいモールを優先し、各モールで集客を行う</p>
      </div>
    </div>
    <div class="cover wrap">
      <div class="min" v-for="item in itemList" :key="item.id">
        <div class="title-cover">
          <img
            :alt="item.title"
            :src="require(`@/assets/common/${item.ImgPath}`)"
          />
          <p class="title" v-html="item.title"></p>
        </div>
        <div class="last" v-html="item.text"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiFreeConsulting from "@/components/Ui/FreeConsulting.vue";
export default {
  name: "marketing-develop",
  components: {
    // UiFreeConsulting,
  },
  data() {
    return {
      itemList: [
        {
          id: 1,
          ImgPath: "syukyaku-kyoutu-maru001.png",
          title: "一発目に出店するモールを選定する",
          text: "<p class=mass>数あるモールの中から、どのモールから出店するか、という検討は非常に重要です。なぜなら、モール内にいるユーザーは、基本的にモール内の検索エンジンを使用して商品を探します。そして、モールによって、検索エンジンの特性が大きく異なります。正確には「特性」といよりも「レベル」です。</p><p class=mass>アメリカ企業であるAmazonの検索エンジンは、同じくアメリカ企業であるGoogleの検索エンジンのようにレベルが高いです。半面、日本企業が提供する楽天市場、Yahoo!ショッピング、auPAYマーケットの検索エンジンは、レベルが低く、また、それぞれの評価軸（アルゴリズム）も異なります。</p><p class=mass>このように、モールによって、商品検索の検索エンジンの特性が異なるため、自社の商材が有利となるモールから出店することが重要になります。その他にも、弊社では、一発目に出店するモールを決定するための判断軸があり、この判断軸にそって、一発目に出店するモールを決定します。</p>",
        },
        {
          id: 2,
          ImgPath: "syukyaku-kyoutu-maru002.png",
          title: "一発目に出店するモールのデータで販売を最適化する",
          text: "<p class=mass>一発目に出店したモールで、販売状況、アクセス数、レビューなどの情報を取得します。そして、そのデータを解析することで、商品を販売に適した商品コンセプトへ、ショップページを閲覧者に刺さるコンテンツへとブラッシュアップしていきます。まずはこのアプローチによって、商品と販売ページを固めることをします。</p>",
        },
        {
          id: 3,
          ImgPath: "syukyaku-kyoutu-maru003.png",
          title: "効率的な立ち上げで日本の主要モールへ展開する",
          text: "<p class=mass>商品と販売ページが固まったら、次なるモールへと出店を広げていきます。このときの最重要ポイントは、一発目に出店したモールで制作したコンテンツを効率的に次なるモールへ流用することで、制作コストと制作期間を削減することです。</p><p class=mass>弊社では、日本の主要モールであるAmazon・楽天市場・Yahoo!ショッピング・auPAYマーケットへの出店を見越して、すべてのモールで効率的にコンテンツを流用して構築するノウハウがあります。Amazonのみサポートしている業者、楽天市場のみサポートしている業者というように、モール特化の業者では対応できない方法で構築します。</p><p class=mass>また、弊社のように日本の主要モールをサポートしている業者でも、業者視点では、モールごとに制作した方が受注金額は大きくなるため、弊社のような効率的な作り方を提案する業者は少ないです。当然ですが、出店に要するコストが低いほど、その投資回収も収益性も良くなります。そのため、弊社は効率的なモール展開を重要事項と位置づけています。</p>",
        },
        {
          id: 4,
          ImgPath: "syukyaku-kyoutu-maru004.png",
          title: "集客する",
          text: "<p class=mass>日本の主要モールへの出店が完了したら、次は、集客です。集客には以下の4つの施策があると、弊社では位置づけています。</p><ul><li>モール内の検索エンジンからの集客</li><li>モール内の広告出稿による集客</li><li>モール外からの集客</li><li>リピート購入による販売</li></ul><p class=mass>集客については、商品1件販売あたりの利益額、商品のリピート性、目標とする売上などを総合的に考慮して、商品ごとに設計して実行していきます。</p><p class=mass>このように、ショッピングモールでの販売は、</p><ul><li>出店順の戦略</li><li>効率的な出店</li><li>出店後の集客</li></ul><p class=last>が大切となり、弊社では、これらすべてをカバーしたサポートをしています。</p>",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.coment {
  margin-bottom: 100px;
}

ul {
  list-style: none;
  li {
    background: url(~@/assets/common/syukyaku-kyoutu-pa001.png) no-repeat 0 0;
    background-size: auto 42px;
    color: var(--main);
    font-size: 26px;
    padding: 0px 0 40px 80px;
  }
}
:deep(h4) {
  font-size: 28px;
  color: var(--main);
  text-align: center;
  padding-bottom: 30px;
  // font-weight: bold;
}
:deep(.left) {
  text-align: left;
  padding-bottom: 10px;
}
.min{
  @media screen and (max-width: 767px){
    margin-bottom: 20px;
  }
}
.box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  border-radius: 50px;
  // margin-bottom: 30px;

  @media screen and (max-width: 767px) {
    // align-items: flex-start;
    padding: 0 30px 20px 30px;
    flex-direction: column;
  }
  img {
    width: 100px;
    margin-right: 10px;
    @media screen and (max-width: 767px) {
      margin-right: 0px;
        object-fit: cover;
        height: 70px;
    }
  }
  p {
    color: var(--main);
    font-size: 24px;
    padding-right: 40px;
    font-size: 23px;
              @media screen and (min-width:768px) and ( max-width:1024px){
      font-size: 18px;
      }
    @media screen and (max-width: 767px) {
      padding-right: 0;
      font-size: 20px;
    }
  }
}
.pt {
  margin-top: 50px;
}
.cover {
  border: var(--main) solid 1px;
  // padding: 50px 0 0 0;
  position: relative;
  margin-top: 100px;
  @media screen and (max-width: 767px) {
    border: none;
    margin-top: 50px;
    padding-right: 20px;
    padding-left: 20px;

  }
}
.title-cover {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 40px;
  @media screen and (max-width: 767px){
    margin-top: 50px;
    margin-bottom: 20px;
  }

  img {
    width: 100px;
    margin-right: 20px;
  }
  .title {
    font-size: 30px;
    color: var(--main);
    width: calc(95% - 100px);
    text-align: left;
    @media screen and (max-width: 767px) {
      font-size: 23px;
    }
  }
}
  :deep(.last){
    margin-bottom: 100px;
    @media screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
  }
  .sp-bt{
@media screen and (max-width: 767px){
  margin-bottom: 80px;
}
  }
</style>
