import { render, staticRenderFns } from "./Ec.vue?vue&type=template&id=3621442e&scoped=true"
import script from "./Ec.vue?vue&type=script&lang=js"
export * from "./Ec.vue?vue&type=script&lang=js"
import style0 from "./Ec.vue?vue&type=style&index=0&id=3621442e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3621442e",
  null
  
)

export default component.exports