<template>
  <div class="company-top">
    <div class="top-cover">
      <img
        alt="会社情報"
        src="@/assets/company/syukyaku-kaisya-001.jpg"
        class="pc-only"
      />
      <img
        alt="会社情報sp"
        src="@/assets/company/syukyaku-kaisya-001sp.jpg"
        class="sp-only"
      />
      <div class="title">
        <p>Company<br />Information</p>
        <h1>会社情報</h1>
      </div>
    </div>
    <div class="wrap">
      <div class="table-cover">
        <UiTable :tableList="tableList" />
        <div class="button-cover min">
          <a
            href="https://greathelp.co.jp/"
            target="_blank"
            rel="noopener noreferrer"
            class="button"
            ><p>会社ホームページを見る</p></a
          >
          <a
            href="https://recruit.greathelp.co.jp/"
            target="_blank"
            rel="noopener noreferrer"
            class="button"
            ><p>求人情報を見る</p></a
          >
        </div>
      </div>
      <div class="img-cover">
        <img alt="会社１" src="@/assets/company/syukyaku-kaisya-002.jpg" />
        <img alt="会社２" src="@/assets/company/syukyaku-kaisya-003.jpg" />
        <img alt="会社３" src="@/assets/company/syukyaku-kaisya-004.jpg"  class="pc-only"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTable from "@/components/Ui/Table.vue";
export default {
  name: "recruit-top",
  components: { UiTable },
  data() {
    return {
      tableList: [
        { id: 1, th: "企業名", td: "株式会社 グレイトヘルプ" },
        { id: 2, th: "代表者名", td: "小関 貴久" },
        { id: 3, th: "本社<br class=sp-only>所在地", td: "岐阜県岐阜市加納桜田町2-5-2" },
        { id: 4, th: "電話番号", td: "058-214-9005" },
        { id: 5, th: "資本金", td: "1,000万円" },
        {
          id: 6,
          th: "事業内容",
          td: "<p class=bold>ウェブマーケティングサポート事業</p><ul class=top><li>ホームページ制作</li><li>ECサイト制作</li><li>システム開発</li><li>インターネット集客支援</li><li>アフィリエイト</li></ul><p class=bold>SaaS事業</p><ul><li>広告成果連携ツール「アドタスカル」</li></ul>",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.top-cover {
  position: relative;
  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
    line-height: 1.2;
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 0 10px;
    }
    p {
      color: white;
      font-size: 65px;
      @media screen and (min-width: 768px) and (max-width: 1024px){
        font-size: 32px;
      }
      @media screen and (max-width: 767px) {
        font-size: 32px;
      }
    }
    h1 {
      color: white;
      font-size: 16px;
      margin: 30px 0 0 0;
    }
  }
}
.table-cover {
  margin: 100px auto;
  :deep(th){
        @media screen and (max-width: 767px) {
    width: 25%;
    line-height: 1.2;
    vertical-align: middle;
    }
  }
}

:deep(ul) {
  margin-top: 0px;
  margin-bottom: 10px;
}

.button-cover {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 100px;
  @media screen and (max-width: 767px) {
  flex-direction: column;
  }
  .button {
    display: block;
    text-align: center;
    border: solid 1px var(--main);
    width: 300px;
    transition: 3s;
    text-decoration: none;
    color: var(--main);
    padding: 20px 0;
    @media screen and (min-width: 768px) and (max-width: 1024px){
      width: 250px;
    }
    @media screen and (max-width: 767px){
      margin-bottom: 20px;
    }
    &:hover {
      cursor: pointer;
      background-color: var(--main);
      color: white;
    }
  }
}
.img-cover {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 767px){
    flex-direction: column;
  }

  img {
    width: 32%;
    @media screen and (max-width: 767px){
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
</style>
