<template>
  <div class="home">
    <div class="top">
      <MallTop />
    </div>

    <div class="pt-150 mb-150">
      <MallMalls />
    </div>

    <!-- <div class="pt-150 pb-150 background-color">
      <EcDevelop />
    </div> -->

    <div class="pt-150 pb-150 background-color2">
      <MallOffer />
    </div>

    <div class="pt-150 pb-150 background-color">
      <MallSell />
    </div>
    <div
      class="parallax_content1"
      v-prlx.mobile.background="{
        direction: 'y',
        fromBottom: true,
        speed: 0.1,
      }"
    ></div>

    <div class="pt-150 pb-150">
      <MallReasons />
    </div>
    <div class="background-color pt-150 pb-150">
      <HomeOurAchievements />
    </div>

    <div class="background-color2 pt-150 pb-150">
      <MallAdvantages />
    </div>

    <div>
      <MallExpand />
    </div>

    <!-- <div class="pt-150">
      <EcMalls />
    </div> -->

    <div class="pt-150 pb-150">
      <MallPrice />
    </div>

    <div class="background-color pt-150 pb-150">
      <MallQa />
    </div>

    <div class="pt-150">
      <MallFlow />
    </div>

    <div class="pt-150 pb-150" :consul="false">
      <HomeContact />
    </div>

    <div class="background-color pt-150 pb-150">
      <HomeServices />
    </div>

    <!-- <div class="background-color2 pt-250 pb-250">
      <HomeUseflInfo />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MallTop from "@/components/Mall/Top.vue";
import MallMalls from "@/components/Mall/Malls.vue";
// import EcDevelop from "@/components/Ec/Develop.vue";
import MallOffer from "@/components/Mall/Offer.vue";
import MallSell from "@/components/Mall/Sell.vue";
import MallReasons from "@/components/Mall/Reasons.vue";
import HomeOurAchievements from "@/components/Home/OurAchievements.vue";
import MallAdvantages from "@/components/Mall/Advantages.vue";
import MallExpand from "@/components/Mall/Expand.vue";
import MallPrice from "@/components/Mall/Price.vue";
import MallQa from "@/components/Mall/Qa.vue";
import MallFlow from "@/components/Mall/Flow.vue";
import HomeContact from "@/components/Home/Contact.vue";
import HomeServices from "@/components/Home/Services.vue";
// import HomeUseflInfo from "@/components/Home/UseflInfo.vue";

export default {
  name: "Mall",
  components: {
    MallTop,
    MallMalls,
    MallOffer,
    MallSell,
    MallReasons,
    HomeOurAchievements,
    MallAdvantages,
    MallExpand,
    MallPrice,
    MallQa,
    MallFlow,
    HomeContact,
    HomeServices,
    // HomeUseflInfo,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
.background-color {
  background-color: var(--sub3);
}
.background-color2 {
  background-color: var(--sub);
}
.parallax_content1 {
  padding: 250px 0;
  background-image: url(~@/assets/mall/syukyaku-EC-mo-back001.jpg);
  background-size: cover;
            @media screen and (min-width:768px) and ( max-width:1024px){
    padding: 150px;
    }
  @media screen and (max-width: 767px) {
    background-size: auto 100vw;
    padding: 120px 0;
  }
}
</style>
