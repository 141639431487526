<template>
  <div class="home-operation-services">
    <h2>Advantages of<br />our EC website development</h2>
    <p class="sub-title">グレイトヘルプのECサイト制作のメリット</p>

    <div
      class="wrap-right"
      :class="{ 'over-baseline': displayData.advantage01 === true }"
    >
      <p
        class="blue-title transparent"
        :class="{ fadeIn: displayData.advantage01 === true }"
      >
        マーケティングと集客のノウハウ・スキルがある
      </p>
      <p
        class="text transparent"
        :class="{ fadeIn: displayData.advantage01 === true }"
      >
        上記のとおり、弊社は、ウェブ制作業者の中でも、有数の実績を保有しています。ECサイトをただ形として作るだけでなく、売上アップや認知拡大、アクセス数増など、希望・目的を実現できるECサイトを作るノウハウとスキルを会社として有しています。その理由は、弊社は、ネット上での完全成果報酬の販売代行事業をルーツとした企業だからです。販売成功すれば報酬をいただける、販売できなければ報酬をいただけないという条件の事業を長年運営している、本物のマーケティング力・集客力があります。そのため、ECビジネスで本格的に売上をのばしていきたいという希望のクライアントのお仕事も受けることができます。
      </p>
    </div>

    <div
      class="wrap-left"
      :class="{ 'over-baseline': displayData.advantage02 === true }"
    >
      <p
        class="blue-title transparent"
        :class="{ fadeIn: displayData.advantage02 === true }"
      >
        マーケティングを駆使し、データに基づいたECサイトの設計
      </p>
      <p
        class="text transparent"
        :class="{ fadeIn: displayData.advantage02 === true }"
      >
        弊社のECサイト制作は、インターネット上の有効なデータを独自のノウハウで収集し、そのデータに基づいて設計していきます。無策・感覚で作ることをしていません。実データに基づいた設計だからこそ、ターゲットに刺さるコンテンツを制作することができます。
      </p>
    </div>

    <div class="wrap-right">
      <p class="blue-title">検索エンジン(SEO対策)を意識したサイトの作り方</p>
      <p class="text">
        専門学校や大学で習うECサイトの作り方や、書籍に書かれている作り方でも、ECサイト自体は制作することは可能です。しかし、ECサイトを通して結果を得るには、それだけでは不十分で、検索エンジン(Google)を意識したサイトの作り方が重要になります。弊社は、常時500サイト以上・10,000以上の検索ワードの検索順位の取得と検証を行っており、Googleのアルゴリズムの普遍的な部分と、日々進化する部分の両方を押さえたECサイトの構築をしています。
      </p>
    </div>

    <div class="wrap-left">
      <p class="blue-title">結果が出ているサイトの構成</p>
      <p class="text">
        オシャレさを追求して奇抜な構成のショップを作ることも可能ですが、やはり、もっともショップのパフォーマンスが上がる最適な構成があります。弊社は、これまで検証と改善を重ね、パフォーマンスが出やすいECサイトの構成をノウハウとして保有しています。弊社にて、安価な内容でECサイトを作る場合も、結果が出ている構成でお作りします。
      </p>
    </div>

    <div class="wrap-right">
      <p class="blue-title">クライアントの目的に合った構築方法の提案</p>
      <p class="text">
        上述のとおり、ECサイトの構築方法は、フルスクラッチから汎用システムの使用まで幅広いです。弊社は全構築方法に対応でき、その中で、クライアントの目的や目標に合った構築方法を検討して提案します。「自社にはこの技術しかないから、この作り方で」という業者都合で構築方法を決定することはありません。
      </p>
    </div>


    <div class="wrap-left">
      <p class="blue-title">クライアントの目的に合った構築方法の提案</p>
      <p class="text">
        弊社はクライアントのご希望とご予算に合わせて、クライアントごとに制作するECサイトの内容を提案しています。
      </p>
      <ul>
        <li>目的達成するための強力なECサイトを制作することも可能</li>
        <li>予算内で実現できるベストなECサイトを提案することも可能</li>
        <li>
          コストをなるべく落とすことを優先したECサイトを提案することも可能
        </li>
      </ul>
      <p class="text">
        クライアントによって、販売する商品・その強み・目標などは千差万別であり、一律の制作内容では、クライアントにとってベストなECサイトはできません。そのため弊社は、一律の制作内容・一律制の料金体系を取っていません。ヒアリングの上、クライアントのニーズにマッチした、本当に必要な機能のみを搭載したECサイトをご提案しています。
      </p>
      <p class="text">
        特に、搭載する機能については、ご予算に合わせて、この機能はあった方がいい、この機能があるとこう便利だ、という説明をしっかりします。イメージを持ちながら、この機能は付けよう、この機能は外してコスト削減しよう、というコスト調整がしっかりとできます。<span class="kome">（※提案の範囲によっては別途料金が発生することがあります。）
      </span></p>
    </div>

    <div class="wrap-right">
      <p class="blue-title">まるまる仕事をお願いできる</p>
      <p class="text">
        ECサイト制作業者の中には、「ECサイトに掲載する文章は書く技術がないから文章はクライアントから提供してください」「撮影はできないから写真は提供してください」というように、全業務を請け負えない会社もあります。弊社は、文章作成・コーディング・デザイン・写真撮影・イラスト作成など、ECサイト制作において必要なことは当然すべて対応できるので、貴社にて手間をかけることなく、また、依頼する業者を分割をすることもなく、すべてお任せすることができます。
      </p>
    </div>

    <div class="wrap-left">
      <p class="blue-title">柔軟なコスト調整</p>
      <p class="text">
        ECサイト制作において必要なことを全て弊社に依頼することもできますが、逆に、依頼内容を調整することで、コストを削減することもできます。たとえば、「文章は貴社にてご用意をいただければこれだけコストが下がります」「写真はあるものを使用することでこれだけコストが下がります」というように、貴社が保有する資源をお聞きし、弊社へ依頼する業務範囲を調整することで、コスト調整ができる仕組みになっています。
      </p>
    </div>

    <div class="wrap-right">
      <p class="blue-title">
        ECサイトの知識がなくても、できるよう要件定義からサポート
      </p>
      <p class="text">
        ECサイトを作りたい、ネット通販をやってみたいけど、どうすればいいのか詳しいことまでは分かっていないという場合でも、大丈夫です。弊社から「ECサイトを立ち上げ・運営するにあたって、この内容を決定する必要があります」ということを全てお教えすることができますので、弊社のサポートのもと、ECビジネスに対する知識がなくても、立ち上げ可能です。
      </p>
    </div>

    <div class="wrap-left">
      <p class="blue-title">決済手段もご提案</p>
      <p class="text">
        ECサイトでの決済は、クレジットカード決済が主です。また、最近では様々なPAYによる支払い、コンビニ払い、後払いなど、多種多様な決済手段があります。決済手段について決定していないという場合は、導入する決済の種類、決済代行会社の選定まで相談可能です。
      </p>
    </div>

    <div class="wrap-right">
      <p class="blue-title">制作後の集客業務まで請け負うことができる</p>
      <p class="text">
       弊社は、ECサイトを制作するだけで、制作後は何もできない、というECサイト制作会社ではありません。ECサイトを通して売上増や認知拡大などのために、制作後の集客業務まで請け負うことができます（別途料金にて）。ただ作るだけでなく、制作後の集客や運用についてもご相談いただけます。
      </p>
    </div>
    <div class="wrap-left">
      <p class="blue-title">事業内容の価値を高める提案も可能</p>
      <p class="text">
        弊社の強みはマーケティング力です。ECサイトを制作するにあたってのマーケティングに基づいて、「貴社の商品はこうしたら、より販売力が高くなる」「このポジションが有利だ」ということまで導くことができます。（※マーケティングまで依頼内容に含む場合）
      </p>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiBox4 from "@/components/Ui/Box4.vue";

export default {
  name: "home-operation-services",
  components: {
    // UiBox4
  },
  data() {
    return {
      documentheight: 0,

      //
      displayData: {
        advantage01: false,
        advantage02: false,
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl", "scrollY"]),
  },
  watch: {
    scrollY(newValue) {
      // advantage01
      if (newValue >= 15560) {
        this.displayData.advantage01 = true;
      }

      // advantage02
      if (newValue >= 16200) {
        this.displayData.advantage02 = true;
      }

      // advantage03
      //
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.wrap-left,
.wrap-right {
  background-color: var(--sub3);
  margin-bottom: 50px;
  transition: all 0.8s ease;
  // clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  @media screen and (max-width: 767px) {
    margin-bottom: 5px;
  }

  .blue-title {
    color: var(--main);
    font-size: 25px;
    margin-bottom: 20px;
    font-weight: 500;
    @media screen and (max-width: 767px) {
      font-size: 23px;
      line-height: 1.5em;
    }
  }
  .text {
    color: var(--black);
  }
  // .transparent {
  //   opacity: 0;
  // }
  .fadeIn {
    transition: opacity 2s 0.8s;
    opacity: 1;
  }
  :deep(ul) {
    margin: 30px 0;
  }
  @media screen and (max-width: 767px) {
    margin-right: 0;
  }
}
.over-baseline {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%) !important;
}

.wrap-left {
  margin-left: calc(calc(100vw - 1100px) * 0.5);
  padding: 50px calc(calc(calc(100vw - 1150px) * 0.5) + 105px) 50px 80px;
  @media screen and (max-width: 1024px) {
    margin-left: 0;
    padding: 40px 20px;
  }
}
.wrap-right:last-child {
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-bottom: 0;
  }
}
.wrap-right {
  margin-right: calc(calc(100vw - 1100px) * 0.5);
  padding: 50px 100px 50px calc(calc(calc(100vw - 1150px) * 0.5) + 105px);
  @media screen and (max-width: 1024px) {
    margin-right: 0;
    padding: 40px 20px;
  }
}
:deep(h2),
.sub-title,
p {
  color: white;
}

:deep(.link-button) {
  a {
    color: white;
    border: white 1px solid;
    &:hover {
      background-color: white;
      color: var(--sub);
    }
  }
}

.over-baseline {
  clip-path: polygon(0 0, 200% 0, 0 200%);
}
</style>
