<template>
  <div class="home-top-class-website wrap">
    <h2>Top-class website<br class="sp-only" />developer<br />in Gifu</h2>
    <h1>
      岐阜のホームページ制作なら<br class="sp-only" />株式会社グレイトヘルプ
    </h1>
    <div>
      <img
        alt="ホームページ制作なら株式会社グレイトヘルプ"
        src="@/assets/syukyaku-top-001.jpg"
        class="pc-only"
      />
      <img
        alt="ホームページ制作なら株式会社グレイトヘルプ"
        src="@/assets/syukyaku-top-001sp.jpg"
        class="sp-only"
      />
      <p>
        岐阜市にある株式会社グレイトヘルプは、クライアントの目的を達成するホームページ制作を得意としています。目的を達成するためのマーケティングと設計によって、
      </p>
      <ul>
        <li>集客・売上アップの実現</li>
        <li>求人・採用の強化</li>
        <li>システムを搭載することによる業務の効率化</li>
        <li>デザインによるイメージアップ</li>
      </ul>
      <p>
        などをホームページの制作で実現します。また、制作後のサイトの改善・SEO対策、Google広告やYahoo!広告などのインターネット広告の運用、SNSの運用なども得意としていて、サポート可能です。クライアントの予算内で実現できる、目的達成のためにベストなホームページをご提案させていただきます。
      </p>
      <div class="pa-cover">
        <img alt="岐阜で創業から13年" src="@/assets/syukyaku-top-pa001.png" />
        <img alt="制作サイト300超え" src="@/assets/syukyaku-top-pa002.png" />
        <img
          alt="解約・他社への切替0件"
          src="@/assets/syukyaku-top-pa003.png"
        />
      </div>
      <div class="pa-cover-sp">
        <img alt="岐阜で創業から13年" src="@/assets/syukyaku-top-pa001sp.png" />
        <img alt="制作サイト300超え" src="@/assets/syukyaku-top-pa002sp.png" />
        <img
          alt="解約・他社への切替0件"
          src="@/assets/syukyaku-top-pa003sp.png"
        />
      </div>
      <div class="coment">
        <p class="kome">(※)ホームページ以外のウェブ制作も含む</p>
        <p class="kome">
          (※)集客・販売強化・求人強化のホームページ制作、ECサイト制作における実績
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "home-top-class-website",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.pa-cover,
.pa-cover-sp {
  width: 70%;
  margin: 30px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 767px) {
    display: none;
  }
  img {
    width: 32%;
  }
}
.pa-cover-sp {
  display: none;
  @media screen and (max-width: 767px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    img {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
.coment {
  text-align: right;
  @media screen and (max-width: 767px){
    text-align: left;
    line-height: 1.5;
  }
}
</style>
