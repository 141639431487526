<template>
  <div class="home-reasons wrap">
    <h2>Reasons to choose<br />our service</h2>
    <p class="sub-title">
      弊社のホームページ制作サービスが<br class="sp-only" />選ばれる理由
    </p>
    <div class="item">
      <div class="text">
        <h3>
          ホームページページ制作のプロとして、<br
            class="pc-only"
          />安心してお仕事をご依頼いただけます
        </h3>
        <p>
          弊社は岐阜市で創業から13年、これまで500サイト以上のウェブ媒体を制作してきました。そして、ただ形としてホームページを作るのではなく、ホームページを通して「結果」「目的達成」を提供しているからこそ、これまで解約や他社への切り替えは0件(※)です。技術の進化が早く、業者の廃業や、制作後の他社への切り替えが発生しやすいホームページ制作において、安心してお仕事をご依頼いただけるのが弊社だと自負しています。<span
            >(※)集客・販売強化・求人強化のホームページ制作、ECサイト制作における実績</span
          >
        </p>
      </div>
      <img alt="理由イメージ１" src="@/assets/syukyaku-top-ira002.png" />
    </div>

    <div class="item col">
      <img alt="理由イメージ２" src="@/assets/ezgif.com-apng-maker-3.png" />
      <div class="text">
        <h3>
          売上アップの実績多数！<br />ホームページを通して目的達成ができます
        </h3>
        <p>
          売上アップや集客増を目的に弊社にご依頼いただいたホームページについて、売上アップの実績が多数あります。年商が2.5倍になった事例、新規集客数が3倍以上になった事例など、インパクトある結果が多数あります。売上アップに限らず、求人強化や顧客リスト獲得など、お客様が達成したい目的を、達成すためのホームページ制作スキームを立案・提案できるのが弊社の強みです。
        </p>
      </div>
    </div>

    <div class="item">
      <div class="text">
        <h3>
          ご予算と達成したい目的をお聞きし、<br
            class="pc-only"
          />予算内で目的を達成するための提案をします
        </h3>
        <p>
          ホームページ制作の実務はわかりづらいため、小難しい話とともに、クライアントの予算を上げるスタンスの業者もいます。弊社は、そのような対応は「トラブルのもと」だと考えていて、クライアントから「ご予算と達成したい目的をお聞きし、予算内で目的を達成するための方法」を考えて、提案します。制作内容の調整などは生じますが、予算オーバーになることなく、安心してご依頼いただけます。また、お客様が投資対効果に見合わないことの実行を希望される場合は、「自社の利益のために仕事を受ける」のではなく、「理由の説明とともに仕事をお断り」する、誠実な対応をしています。
        </p>
      </div>
      <img
        alt="理由イメージ３"
        src="@/assets/syukyaku-top-ira003.png"
        class="sp-right"
      />
    </div>

    <div class="item col">
      <img alt="理由イメージ４" src="@/assets/syukyaku-top-ira004.png" />
      <div class="text">
        <h3>
          各スキルのプロを抱える組織のため、<br
            class="pc-only"
          />すべての業務を対応でき、業務が効率的です
        </h3>
        <p>
          個人事業主や小規模法人のウェブ制作業者は、一人や少人数で業務対応をしています。それに対して、弊社は、マーケティング、プログラム、デザイン、ライティングと、それぞれ専門のスキルを保有するスタッフにて業務を対応しています。そのため、高いスキルの提供が可能です。また、ホームページ以外の業務をご依頼する際も弊社にて対応可能なため、案件によって業者を探す・変える必要がなく、業者を1本化して効率的なご依頼ができます。
        </p>
      </div>
    </div>
    <div class="item">
      <div class="text">
        <h3>
          お客様の事業の可能性を最大限に伸ばすための<br
            class="pc-only"
          />ビジネススキームも提案できます
        </h3>
        <p>
          弊社は、ホームページ制作やコンサルティングを通して、数々の事業の知見を得て、マーケティングスキームやビジネススキームの構築と提案を何度もしてきました。弊社の提案によって、通販事業に乗り出して年商を4倍にしたクライアント、既存のサービスのマーケティングスキームを全面リニューアルして新規獲得を3倍以上にしたクライアントなど、事業を劇的にプラスに変えるスキームの提案も可能です。<br><span class="kome">※ホームページ制作と別途のサービスとしてのご依頼となります。</span>
        </p>
      </div>
      <img
        alt="理由イメージ５"
        src="@/assets/syukyaku-top-ira006.png"
        class="sp-right"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Home-any-concerns-or-problems",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 100px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  h3 {
    color: var(--main);
    text-align: left;
    font-size: 25px;
    margin-bottom: 18px;
    @media screen and (max-width: 767px) {
      font-size: 23px;
    }
  }
  .text {
    width: 75%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    span {
      font-size: 15px;
    }
  }
  img {
    width: 20%;
    @media screen and (max-width: 767px) {
      width: 50%;
      margin: 30px auto 0;
      display: block;
    }
  }
  .sp-right {
    @media screen and (max-width: 767px) {
      margin-right: 0;
    }
  }
}
.col {
  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
  }
}
</style>
