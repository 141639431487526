<template>
  <div class="ui-box">
    <div class="cover">
      <img :src="require(`@/assets/${imgPath}`)" :alt="{ subTitle }" />
      <div class="sub-title">
        <p v-html="subTitle"></p>
      </div>
      <div class="text-area">
        <p v-html="text"></p>
      </div>
      <div class="link-button" v-show="button">
        <a :href="`${linkPath}`" rel="noopener noreferrer"
          >詳細を見る</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiBox1",
  components: {},
  props: {
    imgPath: String,
    subTitle: String,
    text: String,
    linkPath: String,
    button:Boolean,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cover {
  display: flex;
  flex-direction: column;
  align-items: center;

  .sub-title {
    min-height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    color: var(--main);
    text-align: center;
    margin: 20px 0 10px;
    line-height: 1.3em;
    font-weight: 500;
    @media screen and (min-width: 768px) and (max-width: 1150px) {
      font-size: 18px;
    }
    @media screen and (max-width: 767px){
      font-size: 23px;
    }
  }

  .text-area {
    // line-height: 1.5em;
    min-height: 144px;
    p{
      color: var(--black);
    }
  }
}
:deep(.row-3) {
  @media screen and (min-width: 768px) and (max-width: 1150px) {
    font-size: 16px !important;
  }
}
:deep(.row-4) {
  @media screen and (min-width: 768px) and (max-width: 1150px) {
    font-size: 15px !important;
  }
}
:deep(.link-button){
    margin-top: 30px;
}
</style>
