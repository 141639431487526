<template>
  <div class="system-our-thoughts">
    <h2>Our thoughts<br />on system development</h2>
    <p class="sub-title">システム開発に対する弊社の思い</p>
    <div class="wrap">
      <div class="min-white-back">
        <p class="title">
         業界のデメリットを解消し、<br>中小企業にシステムを
        </p>
        <p class="text">
          弊社の代表は、大学を卒業した後、愛知県内の大手システム開発企業でシステムエンジニアとして働いていました。そのとき、どうしても、その在籍していた会社のシステム開発に対するスタンスや、業界のよろしくない風習に納得ができず、退職した経緯があります。
        </p>
        <p class="text">人員を長期間に渡って要し、価格が高くなるシステム開発において、担当者は見積ミスを避けるため、大きな安全値を含めた金額と制作期間をクライアントに提示します。たしかに、何かしらのトラブルが発生したプロジェクトであれば、その安全値のおかげで、そのトラブルによる影響が緩和されることはあるかと思います。しかし、うまくいったプロジェクトほど、クライアントの不利益が大きくなってしまいます。そして、当然金額も高くなるため、大手企業ほどの予算がない中小企業にはシステム化がしづらいという問題も感じました。</p>
      <p class="text">また、もう１つの業界の風習も知りました。クライアントは、大手システム開発企業という安心を買って依頼をしているのにも関わらず、実は、元請の会社はほぼノータッチで、その下請の企業に仕事が渡り、さらにその下請の小さなシステム会社や個人事業主が実務を行っているというケースがあることです。末端業者の技術の完成品が納品されるわけですが、金額は大手システム開発企業の価格です。</p>
      <p class="text">このように、金額として中小企業には難しく、また、クライアントにとってメリットのない業界風習を知り、現在、企業としてシステム開発を行う立場となり、これとは真逆のスタンスでシステム開発を行っていくことにしています。</p>
      
      <img alt="ショッピングカート" src="@/assets/system/syukyaku-sytem-ira004.png" class="town pc-only"/>
      <img alt="ショッピングカート" src="@/assets/system/syukyaku-sytem-ira004sp.png" class="town sp-only"/>
      
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiFreeConsulting from "@/components/Ui/FreeConsulting.vue";
export default {
  name: "system-our-thoughts",
  components: {
    // UiFreeConsulting,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.min-white-back {
  background-color: white;
  padding: 80px;
  @media screen and (max-width: 767px) {
    padding: 50px 20px 20px 20px;
  }
  .title {
    color: var(--main);
    font-size: 35px;
    line-height: 1.5em;
    margin-bottom: 50px;
  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
  font-size: 23px;
  }
  }
  .text {
    margin-bottom: 30px;
  }
  .town{
    margin-top: 20px;
    width: 100%;
  }
}
</style>
