<template>
  <div class="marketing-develop">
    <div class="back pt-150">
      <h2>Types of websites<br />we can develop</h2>
      <p class="sub-title">制作可能なホームページ</p>
      <div class="wrap">
        <div class="back-main">
          <div class="center">
            <p>マーケティングを駆使して集客・販売強化を目指すホームページ</p>
          </div>
        </div>
        <div class="back-sub">
          <div class="center">
            <p>求人・採用の強化を目指す求人専用サイト・求人ページ</p>
          </div>
        </div>
        <div class="back-main">
          <div class="center">
            <p>魅力的なデザインでイメージアップを目指すホームページ</p>
          </div>
        </div>
        <div class="back-sub">
          <div class="center">
            <p>企業としての信頼を演出するコーポレートサイト</p>
          </div>
        </div>
        <div class="back-main">
          <div class="center">
            <p>コストを優先した低コストなホームページ</p>
          </div>
        </div>
        <div class="back-sub">
          <div class="center">
            <p>システムを搭載して業務の自動化・効率化をするホームページ</p>
          </div>
        </div>
        <div class="back-main">
          <div class="center">
            <p>特定の商材の販売強化を目指すランディングページ</p>
          </div>
        </div>
      </div>
    </div>
    <div class="wrap">
      <UiFreeConsulting :backgroundColor="`white`" class="space" :type="`hp`" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiFreeConsulting from "@/components/Ui/FreeConsulting.vue";
export default {
  name: "marketing-develop",
  components: {
    UiFreeConsulting,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.back {
  background-image: url(~@/assets/marketing/syukyaku-syukyaku-back001.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 100px;
  @media screen and (max-width: 767px){
    background-position: right 0% bottom 10%;
  }
}
h2,
.sub-title {
  color: white;
}
.back-main,
.back-sub {
  margin-bottom: 20px;
  @media screen and (max-width: 767px){
    margin-bottom: 5px;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    @media screen and (max-width:1024px) {
      padding-right: 20px;
      padding-left: 20px;
    }

    p {
      color: white;
      font-size: 25px;
      @media screen and (max-width: 1024px){
        font-size: 23px;
      }
    }
&:nth-of-type(1) {
  @media screen and (max-width: 767px) {
    // margin-top: 20px;
  }
}
  }
}
.wrap {
  @media screen and (max-width: 767px) {
    padding: 0;
  }
}
.back-main {
  background-color: var(--main);
      margin-top: 10px;
    @media screen and (max-width: 767px){
      background-color: rgba(23,28,97,0.5);
      margin-top: 5px;
  }

}
.back-sub {
  background-color: var(--sub);
  @media screen and (max-width: 767px){
  background-color: rgba(138,140,175,0.7);
}
}
.space {
  margin-top: 100px;
  @media screen and (max-width: 767px){
    margin-top: 0;
  }
}
:deep(.white-back) {
  @media screen and (max-width: 767px){
    margin-top: 0;
    padding-top:70px;
    padding-bottom:70px;
  }
}
.pt-150{
  @media screen and (max-width: 767px){
    padding-bottom: 70px;
  }
}
</style>
