<template>
  <div class="marketing-develop">
    <h2>How to improve<br />work efficiency</h2>
    <p class="sub-title">ホームページで業務効率化をする方法</p>
    <div class="wrap">
      <p class="min">
        ホームページで業務を効率化・自動化するには、「人の手で行っている業務をウェブ化すること」が本質になります。日々、従業員が時間をかけて対応していることを、ホームページ上で自動でできるようになれば、それだけ大きなコストカット・業務改善になります。
      </p>
      <p class="min">
        そのために重要なことは、現在の業務を把握し、それをウェブ化するための最適な方法を考えてホームページに実装することです。ただホームページを作るだけでなく、業務の視点とシステムの視点の両方からアプローチすることが重要になります。
      </p>
      <p class="min">
        ここで紹介するのは、15施設を運営する介護施設のホームページの事例です。介護業界は、IT化が遅れていて、また、慢性的な人手不足でもあります。採用が追いついていないため、従業員の業務負荷が慢性的に高い状態でした。そんな中、弊社にホームページ制作の依頼がありました。
      </p>
      <p class="min">
        まず、弊社にて、クライアントの業務の現状について、現場でヒアリングと確認をしました。その結果、以下の業務については、ホームページ上にシステムを搭載することで、人による対応をなくし、自動化できることがわかりました。
      </p>
      <ul class="min">
        <li>
          施設の空き状況について電話での問い合わせがあり、空き状況を該当の施設に確認して、お客様へ折り返している
        </li>
        <li>
          施設の入居料金について電話での問い合わせがあり、入居者の介護度区分と介護保険の負担割合をお聞きし、その条件で施設の利用料金を計算して、お客様へお伝えしている
        </li>
        <li>
          施設や入居条件の詳細について電話での問い合わせがあり、管理者が逐一対応している
        </li>
        <li>
          入居希望者のご家族様から施設見学の依頼が電話であり、見学の対応をする管理者が逐一予定を確認して、スケジュール調整をして、お客様へ折り返している
        </li>
      </ul>
      <p class="min">
        これらの解決方法として、弊社は以下を提案し、ホームページに実装しました。
      </p>
    </div>

    <div class="back-main">
      <div class="wrap center">
        <p>
          施設の空き状況をスマホで更新し、<br
            class="tb-sp-only"
          />ホームページ上にリアルタイム表示
        </p>
      </div>
    </div>
    <div class="wrap">
      <img
        alt="施設の空き状況をスマホで更新し、ホームページ上にリアルタイム表示"
        src="@/assets/efficiency/syukyaku-gyoumu-003.jpg"
        class="area-img"
      />
      <p class="mass">
        担当者のスマートフォンから、各施設の空き状況の「空室あり/空室なし」を1回のタップで変更して、ホームページ上にリアルタイム表示するようにしました。これによって、空室状況の問い合わせが減りました。また、電話で空室状況の問い合わせがあった場合も、このページを案内することで、施設に確認することなく、その場で対応ができるようになりました。
      </p>
    </div>

    <div class="back-main">
      <div class="wrap center">
        <p>
          入居者の条件に合わせた施設の<br
            class="tb-sp-only"
          />利用料金をホームページ上で表示
        </p>
      </div>
    </div>
    <div class="wrap">
      <img
        alt="施設の空き状況をスマホで更新し、ホームページ上にリアルタイム表示"
        src="@/assets/efficiency/syukyaku-gyoumu-004.jpg"
        class="area-img2"
      />
      <div class="sankaku"></div>
      <div class="price-cover">
        <img
          alt="利用料金 見積結果"
          src="@/assets/efficiency/syukyaku-gyoumu-005.jpg"
          class="left"
        />
        <div class="right">
          <p>
            ホームページ上で、入居希望者様の介護度区分と介護保険の負担割合を入力し、「料金を表示」を押すと、その条件に合わせた施設の利用料金が表示されます。これによって、お客様がホームページ上で料金を確認できるようになったため、料金に関する問い合わせの対応を削減することができました。
          </p>
          <img
            alt="会社説明"
            src="@/assets/efficiency/syukyaku-gyoumu-ira001.png"
            class="illust"
          />
        </div>
      </div>
    </div>

    <div class="back-main">
      <div class="wrap center">
        <p class="long bg">
          入居希望者とそのご家族様が<br
            class="tb-sp-only"
          />知りたいことはすべて<br class="sp-only" />ホームページ上に掲載
        </p>
      </div>
    </div>
    <div class="wrap">
      <p class="mass">
        これまで質問を受けた内容、入居希望者とそのご家族様が知りたいことは、すべてホームページ上に掲載し、かつ、知りたい情報にカンタンにアクセスできるようにしました。これによって、電話での質問を減らすことができました。また、ホームページ上の情報も充実したため、質問ではなく、入居希望・見学希望のお問い合わせを増やすことにもつながりました。
      </p>
    </div>

    <div class="back-main">
      <div class="wrap center">
        <p>
          ホームページ上で<br class="tb-sp-only" />施設の見学予約ができるように
        </p>
      </div>
    </div>
    <div class="wrap">
      <p class="mass">
        施設の見学の希望があれば、ホームページ上のお問い合わせフォームから「施設の見学予約」を申し込んでいただくと、自動返信メールで見学予約のタイムテーブルが送られ、そのタイムテーブルからご希望の時間に施設の見学予約ができるようにしました。これによって、見学予約の時間調整とやり取りの手間をなくすことができました。
      </p>
      <p class="mass">
        ここで説明したとおり、現在は人の手で対応して「工数を奪われていること」「面倒なこと」をホームページ上で自動でできるようにし、コスト削減を実現します。
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiFreeConsulting from "@/components/Ui/FreeConsulting.vue";
export default {
  name: "marketing-develop",
  components: {
    // UiFreeConsulting,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.coment {
  margin-bottom: 100px;
}
.back-main {
  .center {
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      justify-content: center;
      p{
        text-align: center;
        // font-size: 20p;
      }
    }
    @media screen and (max-width: 767px) {
      padding-left: 0;
      justify-content: center;
      padding-top: 25px;
      padding-bottom: 25px;
      text-align: center;
      p {
        font-size: 22px;
      }
    }
  }
}

.check-list {
  list-style: none;
  li {
    background: url(~@/assets/common/syukyaku-kyoutu-pa001.png) no-repeat 0 0;
    background-size: auto 42px;
    color: var(--main);
    font-size: 26px;
    padding: 0px 0 40px 80px;
  }
}
.area-img,
.area-img2 {
  width: 80%;
  margin-bottom: 50px;
  display: block;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}
.area-img {
  margin: 0 auto 50px;
}
.sankaku {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 50px 0 50px;
  margin-left: 15%;
  border-color: var(--main) transparent transparent transparent;
  @media screen and (max-width: 767px) {
    margin-left: auto;
    margin-right: auto;
  }
}
.price-cover {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  .left {
    width: 40%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 10px;
    }
    img {
      width: 70%;
      @media screen and (max-width: 767px) {
        margin-top: 10px;
      }
    }
  }
}
.bg {
  font-size: 35px;
  @media screen and (max-width: 767px) {
    font-size: 22px;
  }
}
</style>
