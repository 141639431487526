<template>
  <div class="ui-top">
    <div class="top-cover">
      <img
        :src="require(`@/assets/${imgPath}`)"
        :alt="{ ja }"
        class="pc-only"
      />
      <img
        :src="require(`@/assets/${imgPathSp}`)"
        :alt="{ ja }"
        class="sp-only"
      />
      <div class="title">
        <p v-html="en"></p>
        <h1 v-html="ja"></h1>
      </div>
    </div>

    <div class="wrap mt-100">
      <img
        :src="require(`@/assets/${imgPathMain}`)"
        :alt="{ ja }"
        class="main-img"
      />
      <p v-html="text"></p>
      <div v-show="img === true">
        <div class="mark-cover">
          <div class="pc-only">
            <img alt="岐阜で創業12年・" src="@/assets/syukyaku-top-pa001.png" />
            <img alt="制作サイト300超" src="@/assets/syukyaku-top-pa002.png" />
            <img
              alt="解約他社への切替0件"
              src="@/assets/syukyaku-top-pa003.png"
            />
          </div>
          <div class="sp-only">
            <img
              alt="岐阜で創業12年・"
              src="@/assets/syukyaku-top-pa001sp.png"
            />
            <img
              alt="制作サイト300超"
              src="@/assets/syukyaku-top-pa002sp.png"
            />
            <img
              alt="解約他社への切替0件"
              src="@/assets/syukyaku-top-pa003sp.png"
            />
          </div>
        </div>
        <div class="coment">
          <p class="kome">(※)ホームページ以外のウェブ制作も含む</p>
          <p class="kome">
            (※)集客・販売強化・求人強化のホームページ制作、ECサイト制作における実績
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiTop",
  components: {},
  props: {
    imgPath: String,
    imgPathSp: String,
    ja: String,
    en: String,
    imgPathMain: String,
    text: String,
    img: Boolean,
  },
  data() {
    return {
      // img:true
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.ui-top {
  .top-cover {
    position: relative;
    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      text-align: center;
      line-height: 1.2;
      @media screen and (max-width: 767px) {
        width: 100%;
        padding: 0 10px;
      }
      p {
        color: white;
        font-size: 65px;
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          font-size: 32px;
        }
        @media screen and (max-width: 767px) {
          font-size: 32px;
        }
      }
      h1 {
        color: white;
        font-size: 16px;
        margin: 30px 0 0 0;
      }
    }
  }
  .mt-100 {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px) {
      margin-top: 70px;
    }
    .main-img,
    p,
    .mark-cover {
      width: 80%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    p {
      margin-top: 30px;
    }
  }
  .mark-cover {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70% !important;
    margin: 50px auto 20px;
    @media screen and (max-width: 767px) {
      width: 100% !important;
      flex-direction: column;
      margin-bottom: 0;
    }

    img {
      width: 32%;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}
.sp-only {
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}
.coment {
  text-align: right;
    line-height: 1.5;
    width: 100%;
    margin-right: 0;
  .kome{
    margin: 0!important;
  }
  @media screen and (max-width: 767px) {
    text-align: left;
  }
}
</style>
