import { render, staticRenderFns } from "./Merit.vue?vue&type=template&id=408d286e&scoped=true"
import script from "./Merit.vue?vue&type=script&lang=js"
export * from "./Merit.vue?vue&type=script&lang=js"
import style0 from "./Merit.vue?vue&type=style&index=0&id=408d286e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "408d286e",
  null
  
)

export default component.exports