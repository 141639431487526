<template>
  <div class="home-reasons wrap">
    <h2>Expand into major<br />shopping malls</h2>
    <p class="sub-title">
      日本国内主要ショッピングモールへの<br class="sp-only">展開による事業拡大
    </p>

    <div class="img-cover">
      <img alt="楽天市場" src="@/assets/common/syukyaku-kyoutu-logo001.png" />
      <img alt="Amazon" src="@/assets/common/syukyaku-kyoutu-logo002.png" />
      <img alt="Yahoo" src="@/assets/common/syukyaku-kyoutu-logo003.png" />
      <img
        alt="Aupayマーケット"
        src="@/assets/common/syukyaku-kyoutu-logo004.png"
      />
    </div>
    <p class="des">
      自社ECサイトから立ち上げを行った場合、サイトへのアクセスデータを解析し、そのデータを活用して、日本国内の主要ショッピングモールである楽天市場、Amazon、Yahoo!ショッピング、auPAYマーケットへの展開を効果的・効率的に行います。これによって販売の拡大を実現します。
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Home-any-concerns-or-problems",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 20px;
  color: var(--main);
  margin: 50px 0 10px 0;
}
.note {
  text-align: right;
}
.img-cover {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    // flex-direction: column;
    flex-wrap: wrap;
  }
}
img {
  width: 23%;
  @media screen and (max-width: 767px) {
    width: 48%;
    margin-bottom: 20px;
  }
}
.des {
  margin-top: 50px;
      @media screen and (max-width: 767px) {
    margin-top: 20px;
    }
}
</style>
