<template>
  <div class="marketing-offer">
    <h2>Operation services<br />we can offer</h2>
    <p class="sub-title">対応可能な運用系サービス</p>
    <div class="wrap">
      <div class="back-main">
        <div class="center">
          <p>ショップページの更新</p>
        </div>
      </div>
      <div class="back-sub">
        <div class="center">
          <p>ショッピングモール内<br class="sp-only">SEO対策</p>
        </div>
      </div>
      <div class="back-main">
        <div class="center">
          <p>ショッピングモール内の<br class="sp-only">有料広告による集客</p>
        </div>
      </div>
      <img
        alt="ショッピングカート"
        src="@/assets/mall/syukyaku-EC-mo-ira001.png"
        class="cart"
      />
      <!-- <div class="back-sub">
        <div class="wrap center">
          <p>Googleビジネスプロフィールのコンテンツ制作と運用サポート</p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "marketing-develop",
  components: {
    // UiFreeConsulting,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
h2,
p {
  color: white;
}
.back-main,
.back-sub {
  margin-bottom: 20px;
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    @media screen and (max-width: 767px) {
      padding-right: 20px;
      padding-left: 20px;
    }

    p {
      color: white;
      font-size: 25px;
      @media screen and (max-width: 767px) {
        font-size: 23px;
        width: 100%;
        text-align: center;
      }
    }
  }
}
.back-main {
  background-color: var(--main);
  margin-top: 20px;
}
.back-sub {
  background-color: white;
  p {
    color: var(--main) !important;
  }
}
.space {
  margin-top: 100px;
}
.wrap {
  position: relative;
}
.cart {
  position: absolute;
  top: -149px;
  right: 0;
  width: 130px;
  animation-duration: 3s;
  animation-timing-function: ease;
  animation: elm 3s infinite;
  @media screen and (max-width: 1024px) {
    animation: non;
    width: 80px;
    top: -90px;
    
  }
}

@keyframes elm {
  0% {
    transform: translateX(100px);
    opacity: 1;
  }

  100% {
    transform: translateX(-100px);
    opacity: 0;

  }
}

</style>
