<template>
  <div class="marketing-develop">
    <h2>How to develop<br />landing pages</h2>
    <p class="sub-title">売れるランディングページの作り方</p>
    <div class="wrap">
      <p class="min">
        売れるランディングページには「作り方」があります。ランディングページはある意味、科学です。データで証明された勝ちパターンがあります。その勝ちパターンにそって、ページとコンテンツの設計をしていくことで、売れるランディングページができます。
      </p>
      <p class="min">
        弊社では、以下の3点を重要なポイントと位置づけて、ランディングページのマーケティングと制作を行っています。
      </p>
    </div>

    <div class="back-main">
      <div class="wrap center">
        <p>ランディングページの<br class="sp-only" />重要な3要素</p>
        <img
          alt="ランディングページの重要な3要素"
          src="@/assets/common/syukyaku-kyoutu-ira001.png"
          class="man"
        />
      </div>
    </div>
    <div class="wrap">
      <div class="img-cover min tb">
        <img
          alt="集客方法を意識したコンテンツ設計"
          src="@/assets/landingpage/syukyaku-landing-pa001.png"
          class="pc-only"
        />
        <img
          alt="成約率が高いコンテンツ設計と構築"
          src="@/assets/landingpage/syukyaku-landing-pa002.png"
          class="pc-only"
        />
        <img
          alt="成約率に影響があるポイントの作り込み"
          src="@/assets/landingpage/syukyaku-landing-pa003.png"
          class="pc-only"
        />
        <img
          alt="集客方法を意識したコンテンツ設計"
          src="@/assets/landingpage/syukyaku-landing-pa004.png"
          class="sp-only"
        />
        <img
          alt="成約率が高いコンテンツ設計と構築"
          src="@/assets/landingpage/syukyaku-landing-pa005.png"
          class="sp-only"
        />
        <img
          alt="成約率に影響があるポイントの作り込み"
          src="@/assets/landingpage/syukyaku-landing-pa006.png"
          class="sp-only"
        />
      </div>
    </div>
    <div class="cover wrap">
      <div class="min">
        <div class="title-cover">
          <img
            alt="集客方法を意識したコンテンツ設計2"
            src="@/assets/design/syukyaku-de-pa004.png"
          />
          <h4>集客方法を意識した<br class="sp-only" />コンテンツ設計</h4>
        </div>
        <p class="last">
          まず大切なことは、集客方法を意識・想定したランディングページのコンテンツにすることです。ランディングページを単体で考える、設計する、という方法では、高い成約率が出るランディングページにはなりません。どのように、どこから、ライディングページにアクセスを誘導するか、という集客方法を設計し、その設計方法に最適化させたランディングページにすることが重要です。ランディングページに流れてくるアクセス（人）の心理・状況に合わせたコンテンツにする必要があります。
        </p>

        <div class="title-cover">
          <img
            alt="成約率が高いコンテンツ設計と構築2"
            src="@/assets/design/syukyaku-de-pa005.png"
          />
          <h4>成約率が高い<br class="sp-only" />コンテンツ設計と構築</h4>
        </div>
        <p class="mass">
          次に大切なことは、成約率が高いコンテンツの設計です。ランディングページは、データによって導き出された勝ちパターンがあります。いわゆる「型」というものです。その勝ちパターンに基づいて、コンテンツの設計をしていくことで、戦えるランディングページを作ることができます。案件によって、型や掲載するべきコンテンツが異なるため、調査・マーケティングに基づいて、弊社ではコンテンツを設計していきます。
        </p>
        <p class="last">
          そして、ターゲットに向けた響く訴求でキャッチコピーや文章を作成し、情報が見やすい、取得しやすい構成でページを作っていきます。
        </p>
        <div class="title-cover">
          <img
            alt="成約率に影響があるポイントの作り込み2"
            src="@/assets/design/syukyaku-de-pa006.png"
          />
          <h4>成約率に影響がある<br class="sp-only" />ポイントの作り込み</h4>
        </div>
        <p class="mass">
          続いて、ランディングページ内の成約率に大きな影響があるポイントをしっかりと作り込んでいきます。ウェブのマーケティングには「成約率」という概念があります。成約率は以下の計算式で表すことができます。
        </p>

        <h4>成約率＝（購入した人の数÷サイトを訪問した人の数）×100</h4>
        <p class="mass">
          たとえば、100人の人がサイトを訪問して、そのうちの一人が購入したならば、成約率は1%です。
        </p>
        <p class="mass">
          では、サイトを改善して、100人の人がサイトに訪問し、そのうちの二人が購入するようになったとします。この場合、成約率は2%になります。
        </p>
        <p class="mass">
          率としてはたった＋1%です。しかし、購入者は2倍であり、売上も2倍です。このようにランディングページは「成約率」によって、売上が大きく影響を受けます。
        </p>
        <p class="mass">
          成約率を高める方法として一例を挙げれば、「購入ボタン」や「申し込みボタン」の付近の要素の作り込みがあります。ボタンの形状、色、動き、その周辺の文言など、細かなことにこだわって作り込みをします。
        </p>
        <p>
          弊社では、これまでのランディングページの運用によって取得した「勝ちパターンのデータ」を保有しています。この「勝ちパターンのデータ」に基づいて、ランディングページの重要な箇所を作り込んでいきます。
        </p>
      </div>
    </div>

    <div class="blue-back">
      <div class="wrap">
        <div class="back-main no-finish">
          <div class="between">
            <p>ランディングページは<br />作ったら終わりではない</p>
            <img
              alt="ランディングページは作ったら終わりではない"
              src="@/assets/landingpage/syukyaku-landing-ira001.png"
              class="man woman"
            />
          </div>
        </div>
        <p class="mass">
          最後にもっとも重要なことです。ランディングページに限らず、通常のホームページもですが、「作って終わり」ではありません。「作ってからがスタート」です。ランディングページへの集客をする必要もありますし、日々の運用の中で改善を繰り返し行っていくことも大切です。
        </p>
        <p class="mass">
          上でもお伝えしたとおり、成約率が1%から2%になれば、売上は2倍です。そのため、繰り返しの改善は、費用対効果が大きなものになります。
        </p>
        <p style="color: white">
          カンタンに概要を説明すると、ここまでの内容となり、弊社では、このような流れでランディングページを制作していきます。弊社のノウハウをすべて説明しようと思えば、到底ここですべてはお伝えすることはできません。それくらいランディングページは奥が深いものです。お仕事の打ち合わせの際は、より詳しくご説明させていただきます。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiFreeConsulting from "@/components/Ui/FreeConsulting.vue";
export default {
  name: "marketing-develop",
  components: {
    // UiFreeConsulting,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.back-main {
  .center,
  .between {
    @media screen and (max-width: 767px) {
      padding-bottom: 25px;
      padding-top: 25px;
      // padding-left: 0;
    }
  }
  .between {
    margin-top: 150px;
    @media screen and (max-width: 767px) {
      margin-top: 50px;
    }
    p {
      line-height: 1.5;
    }
  }
}

.coment {
  margin-bottom: 100px;
}
.no-finish {
  margin-bottom: 50px;
}

ul {
  list-style: none;
  li {
    background: url(~@/assets/common/syukyaku-kyoutu-pa001.png) no-repeat 0 0;
    background-size: auto 42px;
    color: var(--main);
    font-size: 26px;
    padding: 0px 0 40px 80px;
  }
}
.cover {
  border: var(--main) solid 1px;
  padding: 100px 0;
  position: relative;
  @media screen and (max-width: 767px) {
    padding: 50px 20px 0;
    border: none;
  }
  h4 {
    font-size: 28px;
    color: var(--main);
    text-align: center;
    padding-bottom: 30px;
    // font-weight: bold;
    @media screen and (max-width: 767px) {
      font-size: 23px;
    }
  }
}
.eye {
  position: absolute;
  bottom: 0;
  width: 150px;
  right: 50px;
}

.title-cover {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
  @media screen and (max-width: 767px) {
    align-items: center;
  }
  img {
    width: 100px;
  }
  h4 {
    font-size: 30px;
    color: var(--main);
    width: calc(95% - 100px);
    text-align: left;
    font-weight: normal;
    @media screen and (max-width: 767px) {
      font-size: 23px;
      padding-bottom: 0;
    }
  }
}
.last {
  padding-bottom: 100px;
  @media screen and (max-width: 767px) {
    padding-bottom: 50px;
  }
}
.blue-back {
  background-color: var(--sub);
  padding: 10px 0;
  margin-top: 150px;
  padding-bottom: 150px;
  @media screen and (max-width: 767px) {
    margin-top: 70px;
    padding-bottom: 70px;
  }
  .mass {
    color: white;
  }
}
.img-cover {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 30px;
  }

  img {
    width: 32%;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
.tb
 {
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
  }
}
</style>
